import * as React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';
import Placeholder from './Placeholder';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const LineChart = (props) => {
  const theme = useTheme();
  ChartJS.defaults.font.family = theme.typography.fontFamily;
  ChartJS.defaults.color = theme.palette.text.primary;
  ChartJS.defaults.borderColor = theme.palette.divider;

  const formatUsd = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  });

  const formatDefault = Intl.NumberFormat('en-US', {
    notation: 'compact',
    maximumFractionDigits: 1,
  });

  const formatLabels = (labels) => {
    return labels.map((s) => {
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    });
  };

  let data = {};
  data.labels = formatLabels(props.data.labels);
  data.datasets = props.data.datasets;

  let orientation = 'vertical';
  if (props.type) orientation = props.type;
  if (props.orientation) orientation = props.orientation;

  const options = {
    responsive: true,
    maintainAspectRatio: props.height ? false : true,
    indexAxis: orientation === 'horizontal' ? 'y' : 'x',
    scales: {
      x: {
        grid: {
          display: orientation === 'horizontal' ? true : false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: orientation === 'horizontal' ? props.maxticks : props.maxticksx || data.labels.length,
        },
      },
      y: {
        grid: {
          drawBorder: false,
          display: orientation === 'horizontal' ? false : true,
        },
        ticks: {
          maxTicksLimit: orientation === 'horizontal' ? data.labels.length : props.maxticks,
          callback: function (value, index, values) {
            return props.formaty === 'money' ? formatUsd.format(value) : formatDefault.format(value);
          },
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: props.legend || false,
        position: 'bottom',
        labels: {
          padding: 15,
          usePointStyle: true,
        },
      },
      datalabels: {
        display: false,
      },
    },
  };

  if (props.labelx) {
    options.scales.x.title = {
      display: true,
      text: props.labelx,
      padding: {
        top: 15,
      },
    };
  }

  if (props.labely) {
    options.scales.y.title = {
      display: true,
      text: props.labely,
      padding: {
        bottom: 15,
      },
    };
  }

  if (props.formaty === 'money') {
    options.plugins.tooltip.callbacks = {
      label: function (context) {
        let label = context.dataset.label || '';
        if (label) {
          label += ': ';
        }
        if (context.parsed.y !== null) {
          label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
        }
        return label;
      },
    };
  }

  if (data.datasets[0]?.data?.length > 0) {
    return <Line options={options} data={data} />;
  } else {
    return <Placeholder elevation={0}>No Data</Placeholder>;
  }
};

export default LineChart;
