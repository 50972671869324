import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import { alpha } from '@mui/system';

const DataTableGrid = (props) => {
  const theme = useTheme();

  const onPageChange = (newPage) => {
    if (!props.options.hideFooter) {
      props.options.handlePageChange(newPage + 1);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <DataGrid
        getRowClassName={(params) => (props.options.hasNoWrapField ? `hasnowrap` : ``)}
        rows={props.rows}
        rowCount={Number(props.options.rowCount)}
        pagination
        paginationMode="server"
        page={props.options.currentPage - 1}
        onPageChange={onPageChange}
        columns={props.columns}
        pageSize={props.options.pageSize}
        rowsPerPageOptions={[props.options.pageSize]}
        density="standard"
        columnHeaderTitle
        autoHeight={true}
        disableColumnMenu={true}
        disableSelectionOnClick={true}
        hideFooterSelectedRowCount={true}
        hideFooter={props.options.hideFooter}
        sortingOrder={props.options.tableType === 'filter' ? ['desc'] : ['desc', 'asc']}
        initialState={{
          sorting: {
            sortModel: [{ field: props.options.sortBy, sort: props.options.order ? props.options.order : 'desc' }],
          },
        }}
        experimentalFeatures={{ newEditingApi: true }}
        columnSeparator="display:none"
        localeText={{ noRowsLabel: 'No Data' }}
        onRowClick={props.onRowClick}
        sx={{
          border: 0,
          '& .MuiDataGrid-columnSeparator': { display: 'none' },
          '& .MuiDataGrid-cell, .MuiDataGrid-columnHeaders': {
            borderColor: theme.palette.table.border,
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 600,
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 0,
          },
          '& .MuiDataGrid-row:hover': {
            cursor: props.onRowClick ? 'pointer' : 'default',
          },
          '& .MuiDataGrid-cell[aria-colindex="1"]': {
            color: props.onRowClick ? `${theme.palette.table.link}` : 'inherit',
            textDecoration: props.onRowClick ? 'underline' : 'none',
            textDecorationColor: props.onRowClick ? `${alpha(theme.palette.table.link, 0.4)}` : 'inherit',
          },
          '& .MuiDataGrid-cell:hover': {
            textDecorationColor: 'inherit',
          },
          '& .dontwrapme': {
            whiteSpace: 'normal',
            position: 'relative',
          },
          '& .dontwrapme .MuiDataGrid-cellContent': {
            overflow: 'hidden',
          },
          '& .dontwrapme:after': {
            content: '""',
            position: 'absolute',
            height: '20px',
            width: '50%',
            background: `linear-gradient(90deg, transparent 0%, ${theme.palette.background.paper} 94%)`,
            right: '0',
            bottom: '0',
          },
          '& .hasnowrap': {
            height: { xs: '80px !important', xl: '60px !important' },
            maxHeight: { xs: '80px !important', xl: '60px !important' },
            minHeight: { xs: '80px !important', xl: '60px !important' },
            padding: '10px 0',
            borderBottom: '1px solid ' + theme.palette.table.border,
          },
          '& .hasnowrap:last-child': {
            borderBottom: '0',
          },
          '& .hasnowrap:hover': {
            background: `${theme.palette.table.hover}`,
          },
          '& .hasnowrap:hover .dontwrapme:after': {
            background: `linear-gradient(90deg, transparent 0%, ${theme.palette.table.hover} 94%)`,
          },
          '& .hasnowrap .MuiDataGrid-cell': {
            height: { xs: '60px !important', xl: '40px !important' },
            maxHeight: { xs: '60px !important', xl: '40px !important' },
            minHeight: { xs: '60px !important', xl: '40px !important' },
            alignItems: 'baseline',
            borderBottom: '0',
          },
          '& .MuiDataGrid-virtualScrollerContent': {
            height: {
              xs: props.options.hasNoWrapField ? `calc(80px * ${props.options.pageSize}) !important` : ``,
              xl: props.options.hasNoWrapField ? `calc(60px * ${props.options.pageSize}) !important` : ``,
            },
          },
        }}
      />
    </Box>
  );
};

export default DataTableGrid;
