import React, { useState, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import DataTableGrid from './DataTableGrid';
import SectionTitle from '../SectionTitle';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import AccessRuleDetailsModal from '../modals/AccessRuleDetailsModal';
import { trackWidgetView } from '../../helpers/Analytics';
import ExportButton from '../input/ExportButton';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

const ConversionsByAccessRule = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [fetchingData, setFetchingData] = useState(false);
  const [rows, setRows] = useState(null);
  const [totalRows, setTotalRows] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const colors = theme.palette;
  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);

  const handleDetailsOpen = () => setDetailsOpen(true);
  const handleDetailsClose = () => setDetailsOpen(false);

  const handleRowClick = (param, event) => {
    setSelectedRow(param.row);
    handleDetailsOpen(event);
  };

  const getAccessRules = (props, page) => {
    if (props.apiRequestOptions && !fetchingData) {
      setFetchingData(true);
      page = page ? page : 1;

      var oParams = new URLSearchParams({
        page: page,
        pageSize: 10,
        sort: 'conversions',
        sortDir: 'desc',
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/blox_subscription/access_rule_conversions', oAxiosOptions).then(
        (response) => {
          let rowData = response.data.data.map((x, i) => ({
            ...x,
            id: i,
          }));
          setRows(rowData);
          setTotalRows(response.data.totalRows);
          setCurrentPage(response.data.page);
          setFetchingData(false);
          trackWidgetView({ name: 'Conversions By Access Rule' });
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  const handlePageChange = (newPage) => {
    if (filtersReady && !fetchingData && props.domains) {
      getAccessRules(props, newPage);
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingData && props.domains) {
      getAccessRules(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingConversionsByAccessRule(fetchingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingData]);

  const options = {
    pageSize: 10,
    currentPage: currentPage,
    rowCount: totalRows,
    handlePageChange: handlePageChange,
    sortBy: 'conversions',
  };

  const columns = [
    {
      field: 'label',
      headerName: 'Access Rule',
      sortable: false,
      flex: 1,
    },
    {
      field: 'conversions',
      headerName: 'Conversions',
      sortable: false,
      flex: 0.1,
      headerAlign: 'right',
      type: 'number',
      minWidth: 110,
    },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
        <SectionTitle title="Conversions by Access Rule" tooltip="Must be Audience+ customer to populate." size="sm" />
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disabled={fetchingData}
          onClick={handleMenuToggle}>
          <MoreVertOutlinedIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClick={() => setAnchorEl(null)}
          keepMounted
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            '& .MuiMenuItem-root': {
              gap: 1,
              paddingLeft: '12px',
              fontSize: '.9em',
            },
            '& .MuiSvgIcon-root': {
              fontSize: '1.25rem',
              color: colors.primary.light, 
            },
          }}>
          <MenuItem sx={{ padding: '0 !important' }}>
            <ExportButton
              variant="link"
              sx={{
                color: 'white',
                gap: 1,
                paddingLeft: '12px',
                fontSize: '.9em',
                fontWeight: 400,
              }}
              {...props}
              modal={true}
              export={{
                api: '/api/v1/blox_subscription/access_rule_conversions',
                name: 'conversions-by-access-rule',
                params: {
                  sort: 'conversions',
                  sortDir: 'desc',
                },
              }}
            />
          </MenuItem>
        </Menu>
      </Box>
      {!fetchingData && rows !== null ? (
        <>
          <DataTableGrid columns={columns} rows={rows} options={options} onRowClick={handleRowClick} />
          <AccessRuleDetailsModal
            detailsOpen={detailsOpen}
            handleDetailsOpen={handleDetailsOpen}
            handleDetailsClose={handleDetailsClose}
            accessRule={selectedRow}
            domains={props.domains}
            startDate={props.startDate}
            endDate={props.endDate}
            dateRangeLabel={props.dateRangeLabel}
            apiRequestOptions={props.apiRequestOptions}
          />
        </>
      ) : (
        <Skeleton variant="rectangular" sx={{ mt: 2, height: { xs: 490, xl: 380 } }} />
      )}
    </>
  );
};

export default ConversionsByAccessRule;
