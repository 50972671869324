import React, { useState, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import DataTableGrid from './DataTableGrid';
import SectionTitle from '../SectionTitle';
import DataFilter from '../input/DataFilter';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import DataTypeSelect from '../input/DataTypeSelect';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { trackWidgetView } from '../../helpers/Analytics';
import ExportButton from '../input/ExportButton';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ServiceRateIDGrid = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [fetchingData, setFetchingData] = useState(false);
  const [rows, setRowData] = useState(null);
  const [totalRows, setTotalRows] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [allFilters, setAllFilters] = useState({ rates: [], services: [] });
  const [selectedFilters, setSelectedFilters] = useState({ rates: [], services: [] });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const colors = theme.palette;

  // define which element the menu popover anchors to.
  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const datatypes = [
    {
      type: 'total_subscribers',
      label: 'By Total Subscribers',
    },
    {
      type: 'new_subscribers',
      label: 'By New Subscribers',
    },
    {
      type: 'total_revenue',
      label: 'By Total Revenue',
    },
    {
      type: 'new_revenue',
      label: 'By New Revenue',
    },
  ];

  const [datatype, setDataType] = useState(datatypes[0].type);

  const handleRateChange = (f) => handleFilterChange(f, 'rates');
  const handleServiceChange = (f) => handleFilterChange(f, 'services');

  const handleFilterChange = (f, type) => {
    setCurrentPage(1);
    setSelectedFilters({
      rates: type === 'rates' ? f : selectedFilters.rates,
      services: type === 'services' ? f : selectedFilters.services,
    });
  };

  const addFilters = (newFilters) => {
    let rates = [...new Set([...allFilters.rates, ...newFilters.rates])];
    let services = [...new Set([...allFilters.services, ...newFilters.services])];
    setAllFilters({ rates: rates, services: services });
  };

  const getData = (props, page) => {
    if (props.apiRequestOptions) {
      setRowData(null);
      setFetchingData(true);
      page = page ? page : 1;

      var oParams = new URLSearchParams({
        page: page,
        pageSize: 10,
        sort: datatype,
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      if (selectedFilters.rates.length > 0) oParams.append('rateName', selectedFilters.rates);
      if (selectedFilters.services.length > 0) oParams.append('serviceName', selectedFilters.services);

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/blox_subscription/revenue_by_service', oAxiosOptions).then(
        (response) => {
          let rowData = response.data.data.map((x, i) => ({
            ...x,
            id: i,
            service: x.service,
            rate: x.rate,
            term: x.term,
            price: x.price,
            total_subscribers: x.total_subscribers,
            new_subscribers: x.new_subscribers,
            total_revenue: x.total_revenue,
            new_revenue: x.new_revenue,
          }));

          // find various rates
          let r = [...new Set(rowData.map((e) => e.rate).sort())];

          // find various services
          let s = [...new Set(rowData.map((e) => e.service).sort())];

          addFilters({ rates: r, services: s });
          setRowData(rowData);
          setTotalRows(response.data.totalRows);
          setCurrentPage(response.data.page);
          setFetchingData(false);
          trackWidgetView({ name: 'Service Rate ID Grid', sort: datatype });
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  const handleDataTypeChange = (event) => {
    setDataType(event.target.value);
  };

  const handlePageChange = (newPage) => {
    if (filtersReady && !fetchingData && props.domains) {
      getData(props, newPage);
    }
  };

  // watch for changes that would require reloading data
  useEffect(() => {
    if (filtersReady && !fetchingData && props.domains) {
      getData(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate, datatype, selectedFilters]);

  useEffect(() => {
    props.setLoadingServiceRateIDGrid(fetchingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingData]);

  const options = {
    pageSize: 10,
    currentPage: currentPage,
    rowCount: totalRows,
    handlePageChange: handlePageChange,
    tableType: 'filter',
    sortBy: datatype,
  };

  const columns = [
    {
      field: 'service',
      headerName: 'Service',
      sortable: false,
      flex: 1,
      minWidth: 300,
    },
    {
      field: 'rate',
      headerName: 'Rate',
      sortable: false,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      minWidth: 200,
    },
    {
      field: 'term',
      headerName: 'Term',
      sortable: false,
      flex: 0.1,
      headerAlign: 'right',
      type: 'number',
      minWidth: 55,
    },
    {
      field: 'price',
      headerName: 'Price',
      sortable: false,
      flex: 0.1,
      headerAlign: 'right',
      type: 'number',
      minWidth: 75,
      valueFormatter: (params) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(params.value);
      },
    },
    {
      field: 'total_subscribers',
      headerName: `Total Subscribers`,
      sortable: false,
      flex: 0.1,
      headerAlign: 'right',
      type: 'number',
      minWidth: 165,
      renderHeader: () => (
        <Box className="MuiDataGrid-columnHeaderTitle" sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          {datatype === 'total_subscribers' && <ArrowDownwardIcon sx={{ fontSize: '1.225rem' }} />} {`Total Subscribers`}
        </Box>
      ),
    },
    {
      field: 'new_subscribers',
      headerName: 'New Subscribers',
      sortable: false,
      flex: 0.1,
      headerAlign: 'right',
      type: 'number',
      minWidth: 160,
      renderHeader: () => (
        <Box className="MuiDataGrid-columnHeaderTitle" sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          {datatype === 'new_subscribers' && <ArrowDownwardIcon sx={{ fontSize: '1.225rem' }} />} {`New Subscribers`}
        </Box>
      ),
    },
    {
      field: 'total_revenue',
      headerName: 'Total Revenue',
      sortable: false,
      flex: 0.1,
      headerAlign: 'right',
      type: 'number',
      minWidth: 140,
      valueFormatter: (params) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderHeader: () => (
        <Box className="MuiDataGrid-columnHeaderTitle" sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          {datatype === 'total_revenue' && <ArrowDownwardIcon sx={{ fontSize: '1.225rem' }} />} {`Total Revenue`}
        </Box>
      ),
    },
    {
      field: 'new_revenue',
      headerName: 'New Revenue',
      sortable: false,
      flex: 0.1,
      headerAlign: 'right',
      type: 'number',
      minWidth: 140,
      valueFormatter: (params) => {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: 0,
        }).format(params.value);
      },
      renderHeader: () => (
        <Box className="MuiDataGrid-columnHeaderTitle" sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          {datatype === 'new_revenue' && <ArrowDownwardIcon sx={{ fontSize: '1.225rem' }} />} {`New Revenue`}
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box
        sx={{
          position: 'relative',
          zIndex: '30',
          display: 'flex',
          gap: '0 1em',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}>
        <SectionTitle title="Service Rate ID Grid" tooltip="Must be Audience+ customer to populate." size="sm" />
      </Box>
      <Box sx={{ display: 'flex', gap: 1, justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
        <FormGroup row sx={{ py: 1, pl: 1.5, display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            Filter
          </Typography>
          {allFilters && allFilters.services && (
            <DataFilter
              items={allFilters.services}
              selected={selectedFilters.services}
              minWidth={80}
              label="Service"
              onChange={handleServiceChange}
              disabled={fetchingData}
            />
          )}
          {allFilters && allFilters.rates && (
            <DataFilter
              items={allFilters.rates}
              selected={selectedFilters.rates}
              minWidth={80}
              label="Rate"
              onChange={handleRateChange}
              disabled={fetchingData}
            />
          )}
        </FormGroup>
        <Box sx={{ flexGrow: 1 }} />
        <DataTypeSelect types={datatypes} type={datatype} onChange={handleDataTypeChange} disabled={fetchingData} />
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disabled={fetchingData}
          onClick={handleMenuToggle}>
          <MoreVertOutlinedIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClick={() => setAnchorEl(null)}
          keepMounted
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            '& .MuiMenuItem-root': {
              gap: 1,
              paddingLeft: '12px',
              fontSize: '.9em',
            },
            '& .MuiSvgIcon-root': {
              fontSize: '1.25rem',
              color: colors.primary.light, 
            },
          }}>
          <MenuItem sx={{ padding: '0 !important' }}>
            <ExportButton
              variant="link"
              sx={{
                color: 'white',
                gap: 1,
                paddingLeft: '12px',
                fontSize: '.9em',
                fontWeight: 400,
              }}
              {...props}
              modal={true}
              export={{
                api: '/api/v1/blox_subscription/revenue_by_service',
                name: 'service-rate-id',
                totalRows: totalRows,
                params: {
                  rateName: selectedFilters.rates,
                  serviceName: selectedFilters.services,
                  sort: datatype,
                },
              }}
            />
          </MenuItem>
        </Menu>
      </Box>
      {rows !== null ? (
        <DataTableGrid columns={columns} rows={rows} options={options} />
      ) : (
        <Skeleton variant="rectangular" height={500} />
      )}
    </>
  );
};

export default ServiceRateIDGrid;
