import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PageToolbar from '../components/toolbars/PageToolbar';
import { useOutletContext } from 'react-router-dom';
import Head from '../components/Head';

const Settings = (props) => {
  const { drawerOpen, setDrawerOpen } = useOutletContext();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <Head title="Settings"></Head>
      <PageToolbar handleDrawerToggle={handleDrawerToggle} />
      <Grid container spacing={3} sx={{ py: 3 }}>
        <Grid item xs={12}>
          <Paper
            elevation={2}
            sx={{
              p: 3,
              display: 'flex',
              flexDirection: 'column',
              height: 'auto',
            }}>
            <p>[Settings]</p>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Settings;
