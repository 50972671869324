import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import Kpi from './Kpi';

const EditorialPageviewsKpis = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [kpiData, setKpiData] = useState(null);
  const [fetchingKpiData, setfetchingKpiData] = useState(false);
  const [loadingKpis, setLoadingKpis] = useState(true);

  const fetchKpiData = () => {
    if (props.apiRequestOptions) {
      setLoadingKpis(true);
      setfetchingKpiData(true);

      var oParams = new URLSearchParams({
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/site/page_visitor_summary', oAxiosOptions).then(
        (response) => {
          setKpiData(response.data);
          setfetchingKpiData(false);
          setLoadingKpis(false);
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingKpiData && props.domains) {
      fetchKpiData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingEditorialPageviewKpis(fetchingKpiData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingKpiData]);

  return (
    <>
      <Box sx={{ px: 3 }}>
        {!loadingKpis && kpiData && ['page_views', 'page_views_diff_pct', 'days_diff'].every((key) => key in kpiData) ? (
          <Kpi value={kpiData.page_views} label="Pageviews" diff={kpiData.page_views_diff_pct} period={kpiData.days_diff} />
        ) : (
          <Skeleton variant="rectangular" height={90} />
        )}
      </Box>

      <Divider sx={{ my: 3 }}></Divider>

      {/* unique users kpi */}
      <Box sx={{ px: 3 }}>
        {!loadingKpis && kpiData && ['users', 'users_diff_pct', 'days_diff'].every((key) => key in kpiData) ? (
          <Kpi value={kpiData.users} label="Unique Users" diff={kpiData.users_diff_pct} period={kpiData.days_diff} />
        ) : (
          <Skeleton variant="rectangular" height={90} />
        )}
      </Box>
    </>
  );
};

export default EditorialPageviewsKpis;
