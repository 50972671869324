import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Navigate, useOutletContext } from 'react-router-dom';
import { DateTime } from 'luxon';
import Head from '../components/Head';
import DashboardToolbar from '../components/toolbars/DashboardToolbar';
import DevicesChart from '../components/charts/DevicesChart';
import SourcesChart from '../components/charts/SourcesChart';
import EditorialPageviewsKpis from '../components/kpis/EditorialPageviewsKpis';
import EditorialContentKpis from '../components/kpis/EditorialContentKpis';
import EditorialProductivityKpis from '../components/kpis/EditorialProductivityKpis';
import TopLocations from '../components/grids/TopLocations';
import TopArticles from '../components/grids/TopArticles';
import TopSections from '../components/grids/TopSections';
import TopProducers from '../components/grids/TopProducers';

const Editorial = (props) => {
  const { permissions } = useOutletContext();
  const canViewEditorial = permissions && permissions.editorial;

  const { drawerOpen, setDrawerOpen } = useOutletContext();
  const { apiRequestOptions } = useOutletContext();
  const { userDefaultFilter } = useOutletContext();
  const { domains, setDomains } = useOutletContext();

  const [startDate, setStartDate] = useState(DateTime.now().minus({ days: 7 }));
  const [dateRangeLabel, setDateRangeLabel] = useState('Last 7 Days');
  const [endDate, setEndDate] = useState(DateTime.now().minus({ days: 1 }));
  const [loadingEditorialPageviewKpis, setLoadingEditorialPageviewKpis] = useState(true);
  const [loadingEditorialContentKpis, setLoadingEditorialContentKpis] = useState(true);
  const [loadingEditorialProductivityKpis, setLoadingEditorialProductivityKpis] = useState(true);
  const [loadingTopLocations, setLoadingTopLocations] = useState(true);
  const [loadingTopArticles, setLoadingTopArticles] = useState(false);
  const [loadingTopSections, setLoadingTopSections] = useState(false);
  const [loadingTopProducers, setLoadingTopProducers] = useState(false);
  const [loadingDevicesChart, setLoadingDevicesChart] = useState(false);
  const [loadingSourcesChart, setLoadingSourcesChart] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDomainChange = (event, newValue) => {
    setDomains(newValue);
  };

  const handleDateRangeChange = (start, end, label) => {
    setStartDate(start);
    setEndDate(end);
    setDateRangeLabel(label);
  };

  useEffect(() => {
    if (userDefaultFilter && !domains) {
      setDomains(userDefaultFilter.configuration.domains);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDefaultFilter]);

  if (canViewEditorial === true) {
    return (
      <>
        <Head title="Editorial"></Head>
        <DashboardToolbar
          pageTitle="Editorial"
          handleDrawerToggle={handleDrawerToggle}
          apiRequestOptions={apiRequestOptions}
          domains={domains}
          handleDomainChange={handleDomainChange}
          startDate={startDate}
          endDate={endDate}
          handleDateRangeChange={handleDateRangeChange}
          disabled={
            loadingEditorialPageviewKpis ||
            loadingEditorialContentKpis ||
            loadingEditorialProductivityKpis ||
            loadingTopLocations ||
            loadingTopArticles ||
            loadingTopSections ||
            loadingTopProducers ||
            loadingDevicesChart ||
            loadingSourcesChart
          }
        />
        <Box></Box>
        <Grid container spacing={3} sx={{ py: 3 }}>
          {/* "Overall" section */}
          <Grid item xs={12} lg={4} xl={3}>
            <Paper
              elevation={0}
              sx={{
                py: 3,
                px: 0,
              }}>
              {/* pageviews kpi */}
              <Box sx={{ mb: 2, px: 3 }}>
                <Typography variant="h1" gutterBottom sx={{ mr: 1 }}>
                  Overall
                </Typography>
              </Box>

              <EditorialPageviewsKpis
                domains={domains}
                startDate={startDate}
                endDate={endDate}
                apiRequestOptions={apiRequestOptions}
                setLoadingEditorialPageviewKpis={setLoadingEditorialPageviewKpis}
              />

              <Divider sx={{ my: 3 }}></Divider>

              {/* devices chart */}
              <Box sx={{ px: 3 }}>
                <Typography variant="h2" gutterBottom sx={{ mr: 1 }}>
                  Devices
                </Typography>
                <DevicesChart
                  domains={domains}
                  startDate={startDate}
                  endDate={endDate}
                  apiRequestOptions={apiRequestOptions}
                  setLoadingDevicesChart={setLoadingDevicesChart}
                />
              </Box>

              <Divider sx={{ my: 3 }}></Divider>

              {/* sources pie graph */}
              <Box sx={{ px: 3 }}>
                <Typography variant="h2" gutterBottom sx={{ mr: 1 }}>
                  Top Sources
                  <Tooltip
                    title="The top 8 sources leading users to view content."
                    placement="top"
                    arrow
                    enterTouchDelay={0}>
                    <HelpOutlineIcon sx={{ ml: 0.5, fontSize: '0.7em', verticalAlign: 'middle' }} />
                  </Tooltip>
                </Typography>
                <SourcesChart
                  domains={domains}
                  startDate={startDate}
                  endDate={endDate}
                  apiRequestOptions={apiRequestOptions}
                  setLoadingSourcesChart={setLoadingSourcesChart}
                />
              </Box>

              <Divider sx={{ my: 3 }}></Divider>

              {/* top locations table */}
              <Box sx={{ px: 3 }}>
                <TopLocations
                  domains={domains}
                  startDate={startDate}
                  endDate={endDate}
                  apiRequestOptions={apiRequestOptions}
                  dateRangeLabel={dateRangeLabel}
                  setLoadingTopLocations={setLoadingTopLocations}
                />
              </Box>
            </Paper>
          </Grid>

          {/* content section */}
          <Grid item xs={12} lg={8} xl={9}>
            <Paper
              elevation={0}
              sx={{
                py: 3,
                px: 0,
                mb: 3,
              }}>
              <Box sx={{ px: 3, mb: 2 }}>
                <Typography variant="h1" gutterBottom sx={{ mr: 1 }}>
                  Content
                </Typography>
              </Box>

              {/* content KPIs */}
              <EditorialContentKpis
                domains={domains}
                startDate={startDate}
                endDate={endDate}
                apiRequestOptions={apiRequestOptions}
                setLoadingEditorialContentKpis={setLoadingEditorialContentKpis}
              />

              <Divider sx={{ my: 5 }}></Divider>

              {/* top articles */}
              <Box sx={{ px: 3 }}>
                <TopArticles
                  domains={domains}
                  startDate={startDate}
                  endDate={endDate}
                  apiRequestOptions={apiRequestOptions}
                  setLoadingTopArticles={setLoadingTopArticles}
                />
              </Box>

              <Divider sx={{ my: 5 }}></Divider>

              {/* top sections */}
              <Box sx={{ px: 3 }}>
                <TopSections
                  domains={domains}
                  startDate={startDate}
                  endDate={endDate}
                  apiRequestOptions={apiRequestOptions}
                  setLoadingTopSections={setLoadingTopSections}
                />
              </Box>
            </Paper>

            <Paper elevation={0} sx={{ py: 3, px: 0 }}>
              <Box sx={{ px: 3 }}>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h1" gutterBottom sx={{ mr: 1 }}>
                    Productivity
                  </Typography>
                </Box>

                {/* asset breakdown */}
                <Box>
                  {/* assets published KPI */}
                  <EditorialProductivityKpis
                    domains={domains}
                    startDate={startDate}
                    endDate={endDate}
                    apiRequestOptions={apiRequestOptions}
                    setLoadingEditorialProductivityKpis={setLoadingEditorialProductivityKpis}
                  />
                </Box>
              </Box>

              <Divider sx={{ my: 5 }}></Divider>

              {/* top content producers */}
              <Box sx={{ px: 3, minHeight: '400px' }}>
                <TopProducers
                  domains={domains}
                  startDate={startDate}
                  endDate={endDate}
                  apiRequestOptions={apiRequestOptions}
                  setLoadingTopProducers={setLoadingTopProducers}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  } else if (canViewEditorial === false) {
    return <Navigate to="/signin" replace />;
  }
};

export default Editorial;
