import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import Kpi from './Kpi';
import { trackWidgetView } from '../../helpers/Analytics';

const GamViewability = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [kpiData, setKpiData] = useState(null);
  const [fetchingKpiData, setfetchingKpiData] = useState(false);
  const [loadingKpis, setLoadingKpis] = useState(true);

  const fetchKpiData = () => {
    if (props.apiRequestOptions) {
      setLoadingKpis(true);
      setfetchingKpiData(true);

      var oParams = new URLSearchParams({
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/revenue/gam_viewability', oAxiosOptions).then(
        (response) => {
          setKpiData(response.data);
          setfetchingKpiData(false);
          setLoadingKpis(false);
          trackWidgetView({ name: 'GAM Viewability' });
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingKpiData && props.domains) {
      fetchKpiData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingGamViewability(fetchingKpiData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingKpiData]);

  return (
    <Box sx={{ minWidth: 220, mb: 2 }}>
      {!loadingKpis &&
      kpiData &&
      ['gam_viewability', 'gam_viewability_diff_pct', 'days_diff'].every((key) => key in kpiData) ? (
        <Kpi
          type="percent"
          value={kpiData.gam_viewability}
          label="GAM Viewability"
          diff={kpiData.gam_viewability_diff_pct}
          period={kpiData.days_diff}
        />
      ) : (
        <Skeleton variant="rectangular" height={110} />
      )}
    </Box>
  );
};

export default GamViewability;
