import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { DateTime } from 'luxon';
import Kpi from './Kpi';
import BarChart from '../charts/BarChart';
import { trackWidgetView } from '../../helpers/Analytics';

const GamImpressions = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [kpiData, setKpiData] = useState(null);
  const [fetchingKpiData, setfetchingKpiData] = useState(false);
  const [loadingKpis, setLoadingKpis] = useState(true);
  const [chartData, setChartData] = useState(null);

  const fetchKpiData = () => {
    if (props.apiRequestOptions) {
      setLoadingKpis(true);
      setfetchingKpiData(true);

      var oParams = new URLSearchParams({
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/revenue/gam_impressions', oAxiosOptions).then(
        (response) => {
          setKpiData(response.data);

          var daysAgo = Math.ceil(props.startDate.diffNow('days').days);
          var totalDays = Math.ceil(props.startDate.diff(props.endDate, 'days').days) - 1;
          var dates = null;
          var labels = [];

          // get dates as Luxon date objects and create labels
          if (daysAgo === -7) {
            dates = response.data.impressions_period_stats.map((d) => DateTime.fromISO(d.start_date));
            labels = dates.map((d) => d.weekdayShort);
          } else if (totalDays <= -365) {
            dates = response.data.impressions_period_stats.map((d) => DateTime.fromISO(d.start_date));
            labels = dates.map((d) => d.monthShort);
          } else {
            dates = response.data.impressions_period_stats.map((d) => DateTime.fromISO(d.start_date));
            // if labels all share the same year, remove the /YYYY
            if (dates[0] && dates[0].year === dates[dates.length - 1].year) {
              labels = dates.map((d) => d.toLocaleString({ month: 'numeric', day: 'numeric' }));
            } else {
              labels = dates.map((d) => d.toLocaleString());
            }
          }

          let barChartData = {};
          for (let i = 0; i < response.data.impressions_period_stats.length; i++) {
            const e = response.data.impressions_period_stats[i];
            let d = labels[i];
            barChartData[d] = e.period_impressions;
          }

          setChartData(barChartData);

          setfetchingKpiData(false);
          setLoadingKpis(false);
          trackWidgetView({ name: 'GAM eCPM' });
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingKpiData && props.domains) {
      fetchKpiData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingGamImpressions(fetchingKpiData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingKpiData]);

  return (
    <Grid container spacing={{ xs: 0, sm: 4 }}>
      <Grid item xs={12} sm="auto">
        {!loadingKpis &&
        kpiData &&
        ['total_impressions', 'total_impressions_diff_pct', 'days_diff'].every((key) => key in kpiData) ? (
          <Kpi
            value={kpiData.total_impressions}
            notation="compact"
            label="GAM Impressions"
            diff={kpiData.total_impressions_diff_pct}
            period={kpiData.days_diff}
          />
        ) : (
          <Skeleton variant="rectangular" height={90} width={200} />
        )}
      </Grid>
      <Grid item xs={12} sm>
        {!loadingKpis && chartData ? (
          <Box sx={{ maxWidth: { xl: '95%' }, mt: 2 }}>
            <BarChart data={chartData} type="vertical" labely="Impressions" formaty="compact" maxticksx={7}></BarChart>
          </Box>
        ) : (
          <Skeleton variant="rectangular" height={200} />
        )}
      </Grid>
    </Grid>
  );
};

export default GamImpressions;
