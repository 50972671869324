import { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import LinkIcon from '@mui/icons-material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useCopyToClipboard } from 'usehooks-ts';
import isEqual from 'lodash/isEqual';

const ShareModal = (props) => {
  const copyLink = useCopyToClipboard()[1];
  const [isCopyReady, setCopyReady] = useState(true);
  const [tooltipTitle, setTooltipTitle] = useState('Copy link');
  const [shareLinkLoading, setShareLinkLoading] = useState(true);
  const [shareLinkUrl, setShareLinkUrl] = useState(null);
  const [shareError, setShareError] = useState(null);
  const shareLinkInputEl = useRef(null);

  const config = {
    domains: props.domains,
    section: props.section,
    author: props.author,
    contentTypes: props.contentTypes,
    source: props.source,
    columnVisibility: props.columnVisibility,
  };

  const copyLinkToClipboard = () => {
    setCopyReady(false);
    copyLink(shareLinkUrl);
    setTooltipTitle('Copied!');
    setTimeout(() => {
      setCopyReady(true);
      setTooltipTitle('Copy link');
    }, 2000);
  };

  const handleUserDefaultFilterChange = (filter, config) => {
    filter.configuration = config;
    props.handleUserDefaultFilterChange(filter);
  };

  const createShareLink = () => {
    var oBody = {
      label: 'Trending Dashboard',
      configuration: config,
      is_default: true,
      is_public: true,
    };
    let oOptions = { method: 'POST', headers: props.apiRequestOptions.headers, body: JSON.stringify(oBody) };
    fetch('/api/v1/filters', oOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('API returned ' + response.status + ': ' + response.statusText);
      })
      .then((data) => {
        data.id && setShareLinkUrl(window.location.href + '/' + data.id + '/');
        handleUserDefaultFilterChange(data, config);
      })
      .catch((error) => {
        setShareError({ title: 'Unable to create link', message: error.message });
        return [];
      });
  };

  const updateShareLink = () => {
    var oBody = {
      label: 'Trending Dashboard',
      configuration: config,
      is_default: true,
      is_public: true,
    };
    let oOptions = { method: 'PUT', headers: props.apiRequestOptions.headers, body: JSON.stringify(oBody) };
    fetch('/api/v1/filters/' + props.userDefaultFilter.id, oOptions)
      .then((response) => {
        if (response.ok) {
          return true;
        }
        throw new Error('API returned ' + response.status + ': ' + response.statusText);
      })
      .then((data) => {
        handleUserDefaultFilterChange(props.userDefaultFilter, config);
        setShareLinkUrl(window.location.href + '/' + props.userDefaultFilter.id + '/');
      })
      .catch((error) => {
        setShareError({ title: 'Unable to create link', message: error.message });
        return [];
      });
  };

  const renderShareLink = () => {
    if (shareLinkLoading) {
      return <Skeleton variant="rectangular" height={64} />;
    }
    if (shareLinkUrl) {
      return (
        <TextField
          fullWidth
          size="small"
          defaultValue={shareLinkUrl}
          inputRef={shareLinkInputEl}
          autoFocus
          helperText="Anyone on the internet with this link can view"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={tooltipTitle} placement="top" arrow>
                  <IconButton edge="end" className="adornment-icon" onClick={() => copyLinkToClipboard()}>
                    {isCopyReady ? <ContentCopyIcon fontSize="small" /> : <CheckIcon fontSize="small" />}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      );
    }
    if (shareError) {
      return (
        <Alert severity="error">
          <AlertTitle>{shareError.title}</AlertTitle>
          {shareError.message}
        </Alert>
      );
    }
    var hasFilter = props.userDefaultFilter && props.userDefaultFilter.id;
    return (
      <Box sx={{ textAlign: 'center' }}>
        <Box sx={{ pb: 1 }}>
          <Button variant="contained" onClick={hasFilter ? updateShareLink : createShareLink}>
            {hasFilter ? 'Update' : 'Create'} Share Link
          </Button>
        </Box>
        <Box>
          <Typography variant="subtitle2">
            Anyone {hasFilter ? 'using your link will see these changes.' : 'on the internet with the link can view.'}
          </Typography>
        </Box>
      </Box>
    );
  };

  const compareFilters = () => {
    if (props.userDefaultFilter && props.userDefaultFilter.id) {
      if (isEqual(props.userDefaultFilter.configuration, config)) {
        setShareLinkUrl(window.location.href + '/' + props.userDefaultFilter.id + '/');
      }
    }
    setShareLinkLoading(false);
  };

  useEffect(() => {
    if (shareLinkInputEl.current) {
      shareLinkInputEl.current.select();
    }
  }, [shareLinkUrl]);

  useEffect(() => {
    if (props.shareOpen) {
      setShareLinkUrl(null);
      compareFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shareOpen]);

  return (
    <Dialog
      open={props.shareOpen}
      onClose={props.handleShareClose}
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{
        elevation: 1,
      }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}>
        <LinkIcon /> Share this Dashboard
      </DialogTitle>
      <DialogContent>
        <Table sx={{ mb: 3 }} size="small">
          <TableBody>
            <TableRow>
              <TableCell>Websites</TableCell>
              <TableCell>{props.domains && props.domains.length > 0 ? props.domains.join(', ') : 'All'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Section</TableCell>
              <TableCell>{props.section ? props.section : 'All'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Author</TableCell>
              <TableCell>{props.author ? props.author : 'All'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Content Type</TableCell>
              <TableCell>{props.contentTypes.map((item) => item.label).join(', ')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Traffic Source</TableCell>
              <TableCell>{props.source ? props.source : 'All'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {renderShareLink()}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleShareClose}>Done</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ShareModal;
