import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const TrendingGridSkeleton = () => {
  const gridLines = [...Array(11)].map((item, index) => (
    <Skeleton variant="rectangular" height={65} sx={{ mb: '1px' }} key={index} />
  ));
  return <Box sx={{ mt: 2 }}>{gridLines}</Box>;
};

export default TrendingGridSkeleton;
