import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

const TrendingToolbarColumnsModal = (props) => {
  const theme = useTheme();
  const [columnVisibility, setColumnVisibility] = useState(null);
  const [columnFilters, setColumnFilters] = useState(null);

  // updates switch on / off state
  const toggleSwitches = (event) => {
    let temp = { ...columnVisibility };
    temp[event.target.name] = !temp[event.target.name];
    setColumnVisibility(temp);
  };

  const selectAll = (e) => {
    let temp = { ...columnVisibility };
    for (const key in temp) temp[key] = true;
    setColumnVisibility(temp);
  };

  const applySelected = (e) => {
    props.updateVisibility(columnVisibility);
    props.setUserChangedColumnVisibility(true);
    props.handleColumnsClose();
  };

  const handleColumnsClose = () => {
    props.handleColumnsClose();
  };

  useEffect(() => {
    setColumnFilters(props.columns);
  }, [props.columns]);

  useEffect(() => {
    // only fire on open
    if (props.columnsOpen) setColumnVisibility(props.columnVisibility);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columnsOpen]);

  return (
    <Dialog
      open={props.columnsOpen}
      onClose={handleColumnsClose}
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{
        elevation: 1,
      }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', columnGap: 1, fontWeight: 'bold' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'baseline' }}>
          <Typography variant="h2" gutterBottom sx={{ m: 0, mr: 1 }}>
            Columns
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton aria-label="close" onClick={handleColumnsClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {columnFilters && columnVisibility && (
          <FormControl size="small" sx={{ width: '100%' }}>
            <FormGroup>
              {columnFilters.map((item, i) => {
                return (
                  <FormControlLabel
                    label={<Typography>{item.name}</Typography>}
                    key={i}
                    sx={{
                      mx: 0,
                      py: 0.75,
                      borderBottom: '1px solid ' + theme.palette.neutral.o12,
                      '&.MuiFormControlLabel-root:last-child': {
                        borderBottom: '0',
                      },
                    }}
                    control={
                      <Switch
                        checked={columnVisibility[item.field]}
                        onChange={toggleSwitches}
                        size="small"
                        name={item.field}
                        disabled={item.disabled}
                        color={item.disabled ? 'default' : 'primary'}
                        sx={{ mr: 0.5, ml: -0.75, '&.Mui-disabled': {} }}
                      />
                    }
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        )}

        {/* resets */}
        <Box sx={{ display: 'flex', gap: 1, pt: 3, px: 0 }}>
          <Button onClick={selectAll} sx={{ whiteSpace: 'nowrap', ml: -1.5, px: 1.5 }}>
            Select All
          </Button>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="contained" disableElevation onClick={applySelected} sx={{ whiteSpace: 'nowrap' }}>
            Apply
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TrendingToolbarColumnsModal;
