import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import Kpi from './Kpi';
import IndustryConversionsModal from '../modals/IndustryConversionsModal';
import { trackWidgetView } from '../../helpers/Analytics';

const SubscriptionIndustryKpis = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [fetchingData, setfetchingData] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [meterStopRate, setMeterStopRate] = useState(null);
  const [paidStopRate, setPaidStopRate] = useState(null);
  const [meterStopRateDiff, setMeterStopRateDiff] = useState(null);
  const [paidStopRateDiff, setPaidStopRateDiff] = useState(null);
  const [daysDiff, setDaysDiff] = useState(null);

  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const priorPeriod = daysDiff < 365 ? 'Prior month' : 'Prior year';

  const ResponsiveDivider = ({ children }) => {
    return (
      <Divider
        orientation={mdUp ? 'vertical' : 'horizontal'}
        flexItem
        sx={{ width: { xs: '100%', md: 'auto' }, pt: { xs: 3, md: 0 }, mb: { xs: 3, md: 0 } }}
      />
    );
  };
  /**
   *
   * modal open / close functionality
   */
  const [industryModalOpen, setIndustryModalOpen] = React.useState(false);
  const [paidModalOpen, setPaidModalOpen] = React.useState(false);

  const fetchData = () => {
    if (props.apiRequestOptions) {
      setLoadingData(true);
      setfetchingData(true);

      var oParams = new URLSearchParams({
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/blox_subscription/kpis', oAxiosOptions).then(
        (response) => {
          if (
            response.data &&
            response.data.hasOwnProperty('meter_stop_rate') &&
            response.data.hasOwnProperty('paid_stop_conversion_rate')
          ) {
            let data = response.data;
            setMeterStopRate(data.meter_stop_rate || 0);
            setPaidStopRate(data.paid_stop_conversion_rate || 0);
            setMeterStopRateDiff(data.meter_stop_rate_diff_pct);
            setPaidStopRateDiff(data.paid_stop_conversion_rate_diff_pct);
            setDaysDiff(data.days_diff);
          } else {
            setDisplayError({ message: 'Unexpected data format.' });
          }

          setfetchingData(false);
          setLoadingData(false);
          trackWidgetView({ name: 'Industry KPIs' });
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingData && props.domains) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingSubscriptionIndustryKpis(fetchingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingData]);

  return (
    <Grid container xl={6} sx={{ mt: 2 }}>
      {/* meter stop rate kpi */}
      <Grid item xs={12} md sx={{ pr: { md: 4 } }}>
        <Box name="metric">
          {!loadingData && !Number.isNaN(meterStopRate) ? (
            <Kpi
              value={meterStopRate}
              type="percent"
              label="Meter Stop Rate"
              tip="Unique users who hit the paywall / total unique users."
              diff={meterStopRateDiff}
              period={priorPeriod}
            />
          ) : (
            <Skeleton variant="rectangular" height={90} />
          )}
        </Box>
        {/* industry modal */}
        <IndustryConversionsModal
          domains={props.domains}
          startDate={props.startDate}
          endDate={props.endDate}
          apiRequestOptions={props.apiRequestOptions}
          title="Meter Stop Rate"
          type="metric"
          handleClose={() => setIndustryModalOpen(!industryModalOpen)}
          open={industryModalOpen}
        />
      </Grid>

      <ResponsiveDivider />

      {/* paid stop conversion rate kpi */}
      <Grid item xs={12} md sx={{ px: { md: 4 } }}>
        <Box>
          {!loadingData && !Number.isNaN(paidStopRate) ? (
            <Kpi
              value={paidStopRate}
              type="percent"
              label="Paid Stop Conversion Rate"
              tip="Total digital subscription starts / unique users who hit the paywall."
              diff={paidStopRateDiff}
              period={priorPeriod}
            />
          ) : (
            <Skeleton variant="rectangular" height={90} />
          )}
        </Box>
        {/* industry modal */}
        <IndustryConversionsModal
          domains={props.domains}
          startDate={props.startDate}
          endDate={props.endDate}
          apiRequestOptions={props.apiRequestOptions}
          title="Paid Stop Conversion Rate"
          subtitle="Last 12 Months"
          type="paid"
          handleClose={() => setPaidModalOpen(!paidModalOpen)}
          open={paidModalOpen}
        />
      </Grid>
    </Grid>
  );
};

export default SubscriptionIndustryKpis;
