import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import AddLinkIcon from '@mui/icons-material/AddLink';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth0 } from '@auth0/auth0-react';
import { useDebounce } from 'usehooks-ts';
import BasicDatePicker from '../input/BasicDatePicker';
import { DateTime } from 'luxon';
import AccountMenu from '../AccountMenu';
import { theme } from '../../theme';

const DashboardToolbar = (props) => {
  const { isAuthenticated } = useAuth0();
  const [domainSelectLoading, setDomainSelectLoading] = useState(false);
  const [value, setValue] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [domainOptions, setDomainOptions] = useState([]);
  const debouncedInputValue = useDebounce(inputValue, 400);
  const [dateRangeOptions] = useState(
    props.dateRangeOptions || [
      { value: 'last-7-days', label: 'Last 7 Days' },
      { value: 'last-month', label: 'Last Month' },
      { value: 'last-year', label: 'Last Year' },
      { value: 'custom', label: 'Custom' },
    ]
  );
  const [dateRangeLabel] = useState(props.dateRangeLabel || 'Date Range');
  const [selectedDateRangeValue, setSelectedDateRangeValue] = useState(
    props.dateRangeOptions ? props.dateRangeOptions[0].value : 'last-7-days'
  );
  const [startDate, setStartDate] = useState(props.startDate || DateTime.now().minus({ days: 7 }));
  const [endDate, setEndDate] = useState(props.endDate || DateTime.now().minus({ days: 1 }));
  const [customDateRangeOpen, setCustomDateRangeOpen] = useState(false);
  const [customDateRangeSet, setCustomDateRangeSet] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleShareOpen = (event) => {
    props.handleShareOpen(event);
  };

  const handleDomainChange = (event, newValue) => {
    props.handleDomainChange(event, newValue);
    setValue(newValue);
  };

  const setDateRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', { day: 'numeric', month: 'numeric', year: '2-digit' });
  };

  const handleTimeChange = (event) => {
    let newValue = event.target.value;
    let newLabel = dateRangeOptions.find((obj) => {
      return obj.value === newValue;
    }).label;
    let now = DateTime.now();
    let lastMonth = now.minus({ months: 1 });
    let yesterday = now.minus({ days: 1 });
    let start = yesterday.minus({ days: 6 });
    let end = yesterday;

    if (newValue.startsWith('single-month')) {
      let offset = dateRangeOptions.find((obj) => {
        return obj.value === newValue;
      }).offset;
      start = now.minus({ months: offset }).startOf('month');
      end = now.minus({ months: offset }).endOf('month');
      setDateRange(start, end);
      props.handleDateRangeChange(start, end, newLabel);
      setCustomDateRangeOpen(false);
      setCustomDateRangeSet(false);
    } else {
      switch (newValue) {
        case 'last-7-days':
          setDateRange(start, end);
          props.handleDateRangeChange(start, end, newLabel);
          setCustomDateRangeOpen(false);
          setCustomDateRangeSet(false);
          break;
        case 'last-month':
          start = lastMonth.startOf('month');
          end = lastMonth.endOf('month');
          setDateRange(start, end);
          props.handleDateRangeChange(start, end, newLabel);
          setCustomDateRangeOpen(false);
          setCustomDateRangeSet(false);
          break;
        case 'last-year':
          start = yesterday.minus({ days: 364 });
          end = yesterday;
          setDateRange(start, end);
          props.handleDateRangeChange(start, end, newLabel);
          setCustomDateRangeOpen(false);
          setCustomDateRangeSet(false);
          break;
        case 'custom':
          setCustomDateRangeOpen(true);
          setCustomDateRangeSet(false);
          break;
        default:
          break;
      }
    }
    setSelectedDateRangeValue(newValue);
  };

  const handleSetCustomRange = () => {
    props.handleDateRangeChange(startDate, endDate, 'Custom');
    setCustomDateRangeSet(true);
  };

  const fetchDomains = (searchValue) => {
    if (props.apiRequestOptions) {
      var oParams = new URLSearchParams({
        search: searchValue,
      });
      return fetch('/api/v1/list_sites?' + oParams, props.apiRequestOptions)
        .then((r) => r.json())
        .then((r) => r.data.map((item) => item.hostname))
        .catch((error) => {
          console.error(error);
          return [];
        });
    }
  };

  useEffect(() => {
    if (debouncedInputValue) {
      setDomainSelectLoading(true);
      fetchDomains(debouncedInputValue).then((results) => {
        let newOptions = [];
        if (value.length) {
          newOptions = value;
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setDomainOptions(newOptions);
        setDomainSelectLoading(false);
      });
    } else {
      setDomainOptions(value ? value : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInputValue, value]);

  useEffect(() => {
    if (props.domains && props.domains.length > 0) {
      setValue(props.domains);
    }
  }, [props.domains]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: { xs: 'wrap', lg: 'nowrap' },
        rowGap: '15px',
        columnGap: '5px',
        alignItems: 'center',
      }}>
      <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent: 'center' }}>
        <IconButton color="inherit" aria-label="open drawer" onClick={props.handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
      </Box>

      <Box>
        <Typography variant="pageTitle">{props.pageTitle}</Typography>
      </Box>

      <Box sx={{ flexGrow: 1 }} />

      <Autocomplete
        multiple
        limitTags={1}
        size={isMobile ? 'medium' : 'small'}
        id="domain-select"
        options={domainOptions}
        filterOptions={(x) => x}
        filterSelectedOptions
        noOptionsText={'Type to search...'}
        value={value}
        readOnly={!isAuthenticated}
        onChange={handleDomainChange}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        disabled={props.disabled}
        sx={{
          width: { xs: '100%', lg: 300 },
          order: { xs: 1, lg: 'inherit' },
        }}
        getOptionLabel={(option) => (typeof option === 'string' ? option : '')}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Websites"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {domainSelectLoading ? <CircularProgress color="inherit" size={18} /> : null}
                  {params.InputProps.endAdornment}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
      {props.startDate && props.endDate && (
        <>
          <FormControl
            size={isMobile ? 'medium' : 'small'}
            sx={{
              width: { xs: '100%', lg: selectedDateRangeValue === 'custom' ? 120 : 200 },
              order: { xs: 2, lg: 'inherit' },
            }}>
            <InputLabel id="date-range-select-label">{dateRangeLabel}</InputLabel>
            <Select
              labelId="date-range-select-label"
              id="date-range-select"
              value={selectedDateRangeValue}
              label={dateRangeLabel}
              renderValue={() => {
                if (dateRangeLabel === 'Month')
                  return dateRangeOptions.filter((d) => d.value === selectedDateRangeValue)[0].label;
                else if (selectedDateRangeValue === 'custom') return 'Custom';
                else return formatDate(props.startDate) + ' - ' + formatDate(props.endDate);
              }}
              onChange={handleTimeChange}
              disabled={props.disabled}>
              {dateRangeOptions.map((option, index) => (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {customDateRangeOpen && (
            <Box sx={{ display: 'flex', columnGap: '5px', order: { xs: 3, lg: 'inherit' } }}>
              <BasicDatePicker
                label="Start"
                value={startDate}
                maxDate={endDate}
                onChange={(newValue) => {
                  setStartDate(newValue);
                  setCustomDateRangeSet(false);
                }}
                disabled={props.disabled}
              />
              <BasicDatePicker
                label="End"
                value={endDate}
                minDate={startDate}
                maxDate={DateTime.now().minus({ days: 1 })}
                onChange={(newValue) => {
                  setEndDate(newValue);
                  setCustomDateRangeSet(false);
                }}
                disabled={props.disabled}
              />
              {!customDateRangeSet && (
                <Button onClick={handleSetCustomRange} variant="contained">
                  Go
                </Button>
              )}
            </Box>
          )}
        </>
      )}
      {isAuthenticated && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {props.shareable && (
            <Button onClick={handleShareOpen} disabled={props.disabled} variant="contained" startIcon={<AddLinkIcon />}>
              Share
            </Button>
          )}
          <AccountMenu />
        </Box>
      )}
    </Box>
  );
};

export default DashboardToolbar;
