import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { useTheme } from '@mui/material/styles';

const diffFormatter = new Intl.NumberFormat('default', {
  style: 'percent',
  signDisplay: 'never',
  maximumFractionDigits: 0,
});

function Percentage({ diff, period, theme }) {
  const posDiff = Math.sign(diff) === 1;
  const diffPercent = diffFormatter.format(diff);
  const priorPeriod = typeof period == 'number' ? 'Prior ' + period + ' days' : period;
  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {posDiff ? (
        <Box sx={{ display: 'flex', alignItems: 'center', color: theme.palette.success.main }}>
          <KeyboardDoubleArrowUpIcon fontSize="inherit"></KeyboardDoubleArrowUpIcon>
          {diffPercent}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center', color: theme.palette.error.main }}>
          <KeyboardDoubleArrowDownIcon fontSize="inherit"></KeyboardDoubleArrowDownIcon>
          {diffPercent}
        </Box>
      )}
      <Box>{priorPeriod}</Box>
    </Box>
  );
}

export default function Kpi(props) {
  const theme = useTheme();

  /**
   *
   * @props
   *   value - required
   *   label - required
   *   type  - not required. adds a '$' when type="money", or a '%' when type="percentage"
   *   tip   - not required. adds a tooltip icon / text
   *   diff  - not required. adds prior period comparison.
   *   period  - not required. number of days in prior period, or string value to be displayed.
   *   notation - not required. set standard or compact notation.
   *   maximumFractionDigits - not required. set max fraction digits.
   *   borderBottom - not required. adds a 1px border-bottom when used.
   *   align - not required. control alignment of KPI text.
   * @ex  <Kpi value={98220} label="Pageviews" borderBottom tip="Lorem ipsum" type="money" />
   *
   *
   **/

  let type = 'decimal';
  if (props.type === 'percent' || props.type === 'percentage') type = 'percent';
  if (props.type === 'revenue' || props.type === 'currency' || props.type === 'money') type = 'currency';

  let notation = 'standard';
  if (type === 'decimal' && props.value >= 1000000000) notation = 'compact';
  if (type === 'currency' && props.value >= 1000000) notation = 'compact';
  if (props.notation) notation = props.notation;

  let maximumFractionDigits = 2;
  if (notation === 'compact') maximumFractionDigits = 1;
  if (type === 'currency' && notation === 'standard') maximumFractionDigits = 0;
  if (props.maximumFractionDigits) maximumFractionDigits = props.maximumFractionDigits;

  const kpiFormatter = new Intl.NumberFormat('en-US', {
    style: type,
    currency: 'USD',
    notation: notation,
    maximumFractionDigits: maximumFractionDigits,
    roundingMode: 'trunc',
  });

  const tooltipFormatter = new Intl.NumberFormat('en-US', {
    style: type,
    currency: 'USD',
    notation: 'standard',
    maximumFractionDigits: 0,
  });

  let value = '-';
  if (props.value !== null) {
    value = kpiFormatter.format(props.value);
  }

  let tooltipValue = '';
  if (notation === 'compact') {
    tooltipValue = tooltipFormatter.format(props.value);
  }

  const hasDiff = typeof props.diff === 'number' && props.diff !== 0;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pb: props.borderBottom ? 3 : 0,
        mb: props.borderBottom ? 3 : 0,
        borderBottom: props.borderBottom ? '1px solid ' + theme.palette.divider : '',
        alignItems: props.align ? props.align : '',
      }}>
      <Box>
        <Tooltip title={tooltipValue} followCursor>
          <Typography variant="kpi">{value}</Typography>
        </Tooltip>
      </Box>
      <Box>
        <Typography variant="kpiLabel" component="h3">
          {props.label}
          {props.tip && (
            <Tooltip title={props.tip} placement="top" arrow enterTouchDelay={0}>
              <HelpOutlineIcon sx={{ ml: 0.5, top: -1, position: 'relative', fontSize: '1rem', verticalAlign: 'middle' }} />
            </Tooltip>
          )}
        </Typography>
      </Box>
      {hasDiff && (
        <Box>
          <Typography variant="subtitle2">
            <Percentage diff={props.diff} period={props.period} theme={theme} />
          </Typography>
        </Box>
      )}
    </Box>
  );
}
