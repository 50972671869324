import { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PageviewsChart from '../charts/PageviewsChart';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useTheme } from '@mui/material/styles';
import { Duration } from 'luxon';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { trackDetailsView } from '../../helpers/Analytics';

const ArticleDetailsModal = (props) => {
  const theme = useTheme();
  const [loadingHourlyData, setLoadingHourlyData] = useState(true);
  const [loadingTopSitesData, setLoadingTopSitesData] = useState(true);
  const [rawTopSitesData, setRawTopSitesData] = useState(null);
  const [topSitesData, setTopSitesData] = useState(null);
  const [topSitesError, setTopSitesError] = useState(null);
  const emptyChart = { labels: [], datasets: [] };
  const [pageviewsChartData, setPageviewsChartData] = useState(emptyChart);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [changingSelectedDomain, setChangingSelectedDomain] = useState(false);

  const stateRef = useRef();
  stateRef.selectedDomain = selectedDomain;

  const formatHour = (val) => {
    var date = new Date(val);
    var localHour = date.toLocaleString('en', { hour: 'numeric', hour12: true });
    return localHour;
  };

  const formatSubPercent = (data) => {
    let percentage = '-';
    data.data.forEach((item) => {
      if (item.origin_hostname === stateRef.selectedDomain) {
        let subs = item.subscribers;
        let total = item.users;
        percentage = ((subs * 100) / total).toFixed(0);
      }
    });
    return percentage;
  };

  const formatAvTime = (data) => {
    let time = '-';
    data.data.forEach((item) => {
      if (item.origin_hostname === stateRef.selectedDomain) {
        time = Duration.fromObject({ seconds: item.avg_engage_time })
          .shiftTo('minutes', 'seconds')
          .normalize()
          .toHuman({ unitDisplay: 'short' });
      }
    });
    return time;
  };

  const formatDevices = (data) => {
    let devices = [];
    data.data.forEach((item) => {
      if (item.origin_hostname === stateRef.selectedDomain) {
        devices = item.devices;
      }
    });
    let total = 0;
    devices.forEach((item) => {
      total += item.total;
    });
    devices.forEach((item) => {
      item.label = item.label.charAt(0).toUpperCase() + item.label.slice(1);
      item.percentage = ((item.total * 100) / total).toFixed(0);
    });
    return devices;
  };

  const formatSources = (data) => {
    let sources = [];
    data.data.forEach((item) => {
      if (item.origin_hostname === stateRef.selectedDomain) {
        sources = item.sources;
      }
    });
    sources.length = Math.min(sources.length, 5);
    let total = 0;
    sources.forEach((item) => {
      total += item.total;
    });
    sources.forEach((item) => {
      if (item.label === '(direct)') {
        item.label = 'Direct';
      }
      item.label = item.label.charAt(0).toUpperCase() + item.label.slice(1);

      item.percentage = ((item.total * 100) / total).toFixed(0);
    });
    return sources;
  };

  const formatTopSites = (data) => {
    var sites = data.data.filter((site, index) => {
      return (index < 6 && site.origin_hostname !== '*') || site.origin_hostname === props.article.hostname;
    });
    sites.forEach((item) => {
      item.percentage = ((item.users * 100) / data.totalUsers).toFixed(1);
    });
    return sites;
  };

  const fetchHourlyData = (articleId) => {
    if (props.apiRequestOptions) {
      var fetchUrl = '/api/v1/content_details/' + articleId + '/hourly';
      var hostFilter = selectedDomain || props.article.hostname;
      if (hostFilter && hostFilter !== '*') {
        var oParams = new URLSearchParams({
          hostname: hostFilter,
        });
        fetchUrl += '?' + oParams;
      }
      fetch(fetchUrl, props.apiRequestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error(response.status + ': ' + response.statusText);
        })
        .then((data) => {
          var hourlyData = data.data.filter(function (f) {
            return f.users !== 0 || f.subscribers !== 0;
          });
          setPageviewsChartData({
            labels: hourlyData.map((item) => formatHour(item.hour)),
            datasets: [
              {
                label: 'Total',
                fill: '1',
                data: hourlyData.map((item) => item.users),
                borderColor: theme.palette.chart.blue.main,
                backgroundColor: theme.palette.chart.blue.o50,
                tension: 0.2,
              },
              {
                label: 'Subscribers',
                fill: true,
                data: hourlyData.map((item) => item.subscribers),
                borderColor: theme.palette.chart.green.main,
                backgroundColor: theme.palette.chart.green.o50,
                tension: 0.2,
              },
            ],
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoadingHourlyData(false);
          setChangingSelectedDomain(false);
        });
    }
  };

  const fetchTopSitesData = (articleId) => {
    if (props.apiRequestOptions) {
      fetch('/api/v1/content_details/' + articleId + '/top_sites', props.apiRequestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error(response.status + ': ' + response.statusText);
        })
        .then((data) => {
          setRawTopSitesData(data);
          setTopSitesData({
            subPercent: formatSubPercent(data),
            avTime: formatAvTime(data),
            devices: formatDevices(data),
            sources: formatSources(data),
            topSites: formatTopSites(data),
          });
        })
        .catch((error) => {
          setTopSitesError({ title: 'Data unavailable', message: error.message });
          return false;
        })
        .finally(() => {
          setLoadingTopSitesData(false);
        });
    }
  };

  const handleDetailsClose = () => {
    props.handleDetailsClose();
  };

  useEffect(() => {
    if (props.detailsOpen) {
      setLoadingHourlyData(true);
      setLoadingTopSitesData(true);
      setPageviewsChartData(emptyChart);
      setSelectedDomain(props.article.hostname);
      fetchHourlyData(props.article.canonical_content_id);
      fetchTopSitesData(props.article.canonical_content_id);
      trackDetailsView({ name: 'Article Details', widgetName: 'Trending' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.detailsOpen]);

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDomainChange = (event) => {
    setSelectedDomain(event.target.value);
  };

  useEffect(() => {
    if (props.article && props.article.hostname) {
      setSelectedDomain(props.article.hostname);
    }
  }, [props.article]);

  useEffect(() => {
    if (selectedDomain && rawTopSitesData) {
      setChangingSelectedDomain(true);
      setTopSitesData({
        subPercent: formatSubPercent(rawTopSitesData),
        avTime: formatAvTime(rawTopSitesData),
        devices: formatDevices(rawTopSitesData),
        sources: formatSources(rawTopSitesData),
        topSites: formatTopSites(rawTopSitesData),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDomain]);

  useEffect(() => {
    if (props.detailsOpen && changingSelectedDomain && !loadingHourlyData && topSitesData) {
      setLoadingHourlyData(true);
      fetchHourlyData(props.article.canonical_content_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topSitesData]);

  const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    backgroundColor: 'transparent',
    borderBottom: '1px solid ' + theme.palette.divider,
    '&:before': {
      display: 'none',
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
    '&.Mui-expanded:before': {
      opacity: 0,
    },
    '& .MuiAccordionSummary-root.Mui-disabled': {
      opacity: 1,
      pointerEvents: 'auto',
    },
    '& .MuiAccordionSummary-content': {
      margin: '15px',
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)({
    padding: '0 0 20px 0',
  });

  const StyledOl = styled('ol')(({ theme }) => ({
    listStyle: 'none',
    listStyleType: 'none',
    display: 'flex',
    columnGap: '26px',
    justifyContent: 'flex-start',
    padding: 0,
    margin: '1em 0',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    '& li': {
      counterIncrement: 'item',
      flexGrow: 1,
      flexShrink: 1,
      minWidth: 0,
    },
    '& li .site-container': {
      display: 'flex',
      marginBottom: '1em',
      [theme.breakpoints.up('sm')]: {
        marginBottom: 0,
      },
    },
    '& li .site-container:before': {
      content: 'counter(item)',
      fontSize: '1.5rem',
      lineHeight: '1.5rem',
      fontWeight: 'bold',
      paddingRight: '6px',
      marginRight: '6px',
      borderRight: '1px solid ' + theme.palette.divider,
      color: theme.palette.link.main,
    },
    '& li .site-container div': {
      fontSize: '0.9rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }));

  return (
    <Dialog
      open={props.detailsOpen}
      onClose={handleDetailsClose}
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        elevation: 1,
      }}
      TransitionProps={{
        onExited: () => {
          setSelectedDomain(null);
        },
      }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'flex-start', columnGap: 1, fontWeight: 'bold' }}>
        <Box>
          <Typography variant="h2">
            {props.article.title}
            <IconButton color="link" size="small" target="_blank" href={props.article.url} sx={{ ml: 1 }}>
              <OpenInNewIcon />
            </IconButton>
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton aria-label="close" onClick={handleDetailsClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" sx={{ mb: { xs: 2, sm: 2 } }}>
                Last 24 hours
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              {!loadingTopSitesData && topSitesData && topSitesData.topSites.length > 1 && (
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <FormControl sx={{ m: 0.5, width: { xs: '100%', sm: 'auto' }, minWidth: 120 }} size="small">
                    <InputLabel id="details-domain-select">Website</InputLabel>
                    <Select
                      labelId="details-domain-select"
                      id="details-domain-select"
                      value={selectedDomain || ''}
                      label="Website"
                      onChange={handleDomainChange}
                      disabled={loadingHourlyData}>
                      <MenuItem value="*">All sites</MenuItem>
                      {topSitesData.topSites.map((site) => (
                        <MenuItem key={site.origin_hostname} value={site.origin_hostname}>
                          {site.origin_hostname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6} md={7}>
              <Box sx={{ mt: { xs: 3, md: 0 } }}>
                {loadingHourlyData ? (
                  <Skeleton variant="rectangular" height={378} />
                ) : (
                  <>
                    <Typography variant="h3" sx={{ mr: 1 }}>
                      Active Users
                    </Typography>
                    <Box sx={{ height: '360px' }}>
                      <PageviewsChart chartType="line" showLegend={true} chartData={pageviewsChartData} />
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              {loadingTopSitesData ? (
                <Skeleton variant="rectangular" height={240} />
              ) : (
                <>
                  {topSitesError && (
                    <Alert severity="error">
                      <AlertTitle>{topSitesError.title}</AlertTitle>
                      {topSitesError.message}
                    </Alert>
                  )}
                  {topSitesData && (
                    <Box sx={{ mt: { xs: 4, sm: 0 } }}>
                      <Accordion disabled>
                        <AccordionSummary sx={{ mr: '24px' }}>
                          <Typography
                            sx={{
                              width: '50%',
                              flexShrink: 0,
                              textAlign: 'center',
                              color: theme.palette.primary.main,
                              fontSize: '3rem',
                              lineHeight: '3rem !important',
                            }}>
                            {topSitesData.subPercent}
                            <sup style={{ fontSize: '1.4rem', lineHeight: '1.4rem', verticalAlign: 'super' }}>&#37;</sup>
                          </Typography>
                          <Typography sx={{ fontSize: '1.2rem', lineHeight: '3rem' }}>Subscribers</Typography>
                        </AccordionSummary>
                      </Accordion>
                      <Accordion disabled>
                        <AccordionSummary sx={{ mr: '24px' }}>
                          <Typography sx={{ width: '50%', flexShrink: 0 }}>
                            Av. Time
                            <Tooltip title={'Average engagement time per active user.'} placement="top" arrow>
                              <HelpOutlineIcon sx={{ ml: 0.5, fontSize: '1em', verticalAlign: 'middle' }} />
                            </Tooltip>
                          </Typography>
                          <Typography variant="data">{topSitesData.avTime}</Typography>
                        </AccordionSummary>
                      </Accordion>
                      {topSitesData.devices && topSitesData.devices.length > 0 && (
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                            id="panel3bh-header">
                            <Typography sx={{ width: '50%', flexShrink: 0 }}>Top Device</Typography>
                            <Typography variant="data">
                              {topSitesData.devices[0].percentage}&#37; {topSitesData.devices[0].label}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box sx={{ p: '0 40px 0 16px' }}>
                              <ul style={{ listStyleType: 'none', padding: '0 0 0 50%', margin: 0, fontSize: '0.9rem' }}>
                                {topSitesData.devices.slice(1).map((device) => (
                                  <li key={device.label}>
                                    <Typography variant="data">
                                      {device.percentage}&#37; {device.label}
                                    </Typography>
                                  </li>
                                ))}
                              </ul>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      )}
                      {topSitesData.sources && topSitesData.sources.length > 0 && (
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header">
                            <Typography sx={{ width: '50%', flexShrink: 0 }}>Top Source</Typography>
                            <Typography variant="data">
                              {topSitesData.sources[0].percentage}&#37; {topSitesData.sources[0].label}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Box sx={{ p: '0 40px 0 16px' }}>
                              <ul style={{ listStyleType: 'none', padding: '0 0 0 50%', margin: 0, fontSize: '0.9rem' }}>
                                {topSitesData.sources.slice(1).map((source) => (
                                  <li key={source.label}>
                                    <Typography variant="data">
                                      {source.percentage}&#37; {source.label}
                                    </Typography>
                                  </li>
                                ))}
                              </ul>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          {!loadingTopSitesData && topSitesData && topSitesData.topSites.length > 1 && (
            <Grid item xs={12}>
              <Box sx={{ mt: { xs: 6, md: 3 } }}>
                <Typography variant="h3" gutterBottom sx={{ mr: 1 }}>
                  Top Sites
                </Typography>
                <StyledOl>
                  {topSitesData.topSites.slice(0, 5).map((site) => (
                    <li key={site.origin_content_id}>
                      <div className="site-container">
                        <div>
                          <div style={{ fontWeight: 'bold' }}>{site.origin_hostname}</div>
                          <div>{site.percentage}&#37;</div>
                        </div>
                      </div>
                    </li>
                  ))}
                </StyledOl>
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ArticleDetailsModal;
