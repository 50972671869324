import * as React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';
import Placeholder from './Placeholder';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

/**
 *
 * @props maxticks, data, type=horizontal|''
 * @ex  <BarChart maxticks="8" data={data} type="{type}" ></BarChart>
 * @data example: {
 *   'lineitem1': num,
 *   'lineitem2': num,
 * }
 *
 **/

const BarChart = (props) => {
  const theme = useTheme();
  ChartJS.defaults.font.family = theme.typography.fontFamily;
  ChartJS.defaults.color = theme.palette.text.primary;
  ChartJS.defaults.borderColor = theme.palette.divider;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    // notation: 'compact',
    maximumFractionDigits: 0,
  });

  const formatLabels = (labels) => {
    return labels.map((s) => {
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    });
  };

  let data = {};
  data.labels = formatLabels(Object.keys(props.data));
  data.datasets = [];
  data.datasets[0] = {
    data: Object.values(props.data),
    backgroundColor: theme.palette.chart.blue.main,
  };

  let orientation = 'vertical';
  if (props.type) orientation = props.type;
  if (props.orientation) orientation = props.orientation;

  const options = {
    responsive: true,
    maintainAspectRatio: props.height ? false : true,
    indexAxis: orientation === 'horizontal' ? 'y' : 'x',
    scales: {
      x: {
        grid: {
          display: orientation === 'horizontal' ? true : false,
          drawBorder: false,
        },
        ticks: {
          maxTicksLimit: orientation === 'horizontal' ? props.maxticks : props.maxticksx || data.labels.length,
        },
      },
      y: {
        grid: {
          drawBorder: false,
          display: orientation === 'horizontal' ? false : true,
        },
        ticks: {
          maxTicksLimit: orientation === 'horizontal' ? data.labels.length : props.maxticks,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'start',
        align: 'end',
        display: false,
        formatter: (value) => {
          return formatter.format(value);
        },
      },
    },
  };

  if (props.labelx) {
    options.scales.x.title = {
      display: true,
      text: props.labelx,
      padding: {
        top: 15,
      },
    };
  }

  if (props.formatx) {
    options.scales.x.ticks.callback = (value, index, values) => {
      return Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits: 1,
      }).format(value);
    };
  }

  if (props.labely) {
    options.scales.y.title = {
      display: true,
      text: props.labely,
      padding: {
        bottom: 15,
      },
    };
  }

  if (props.formaty) {
    options.scales.y.ticks.callback = (value, index, values) => {
      return Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits: 1,
      }).format(value);
    };
  }

  if (data.datasets[0]?.data?.length > 0) {
    return <Bar options={options} data={data} />;
  } else {
    return <Placeholder elevation={0}>No Data</Placeholder>;
  }
};

export default BarChart;
