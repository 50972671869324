import { Helmet } from 'react-helmet-async';
import { PRODUCT_NAME } from '../constants';

export default function Head({ title = '', description = '' }) {
  return (
    <Helmet>
      <title>
        {title && title + ' - '}
        {PRODUCT_NAME}
      </title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
}
