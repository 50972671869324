import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import DataTableGrid from '../grids/DataTableGrid';
import { trackDetailsView } from '../../helpers/Analytics';
import ExportButton from '../input/ExportButton';

const AccessRuleDetailsModal = (props) => {
  const { setDisplayError } = useOutletContext();
  const [fetchingGridData, setFetchingGridData] = useState(false);
  const [rows, setRows] = useState(null);
  const [totalRows, setTotalRows] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const handleDetailsClose = () => {
    props.handleDetailsClose();
  };

  const fetchGridData = (accessRuleName, page) => {
    if (!fetchingGridData && props.apiRequestOptions) {
      setFetchingGridData(true);
      page = page ? page : 1;

      let oParams = new URLSearchParams({
        page: page,
        pageSize: 5,
        sort: 'conversions',
        sortDir: 'desc',
        accessRuleName: accessRuleName,
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios
        .get('/api/v1/blox_subscription/access_rule_conversions', oAxiosOptions)
        .then(
          (response) => {
            let rowData = response.data.data.map((x, i) => ({
              ...x,
              id: i,
            }));
            setRows(rowData);
            setTotalRows(response.data.totalRows);
            setCurrentPage(response.data.page);
            setFetchingGridData(false);
            trackDetailsView({ name: 'Access Rule Details', widgetName: 'Conversions By Access Rule' });
          },
          (error) => {
            setDisplayError(error);
          }
        )
        .finally(() => {
          setFetchingGridData(false);
        });
    }
  };

  const handlePageChange = (newPage) => {
    fetchGridData(props.accessRule.label, newPage);
  };

  useEffect(() => {
    if (props.detailsOpen) {
      fetchGridData(props.accessRule.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.detailsOpen]);

  const options = {
    pageSize: 5,
    currentPage: currentPage,
    rowCount: totalRows,
    handlePageChange: handlePageChange,
    sortBy: 'conversions',
  };

  const columns = [
    {
      field: 'label',
      headerName: 'Service',
      sortable: false,
      flex: 1,
    },
    {
      field: 'conversions',
      headerName: 'Conversions',
      sortable: false,
      flex: 0.1,
      headerAlign: 'right',
      type: 'number',
      minWidth: 110,
    },
  ];

  return (
    <Dialog
      open={props.detailsOpen}
      onClose={handleDetailsClose}
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        elevation: 1,
      }}
      TransitionProps={{
        onExited: () => {
          setRows(null);
        },
      }}>
      <DialogContent>
          {props.accessRule.label && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h2" gutterBottom sx={{ m: 0, mr: 1 }}>
                {props.accessRule.label}
              </Typography>
              <ExportButton
                {...props}
                modal={true}
                iconOnly={true}
                export={{
                  api: '/api/v1/blox_subscription/access_rule_conversions',
                  name: 'conversions-by-access-rule-' + props.accessRule.label.toLowerCase().replace(/ /g, '-'),
                  totalRows: totalRows,
                  params: {
                    sort: 'conversions',
                    sortDir: 'desc',
                    accessRuleName: props.accessRule.label,
                  },
                }}
              />
              <Box sx={{ flexGrow: 1 }} />
              <IconButton aria-label="close" onClick={handleDetailsClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          {props.accessRule.label && props.accessRule.conversions && (
            <Typography variant="subtitle2" gutterBottom sx={{ m: 0 }}>
              {props.accessRule.conversions.toLocaleString()} conversion{props.accessRule.conversions > 1 ? 's ' : ' '}
              {props.dateRangeLabel === 'Last Year' ? props.dateRangeLabel.toLowerCase() : ' in ' + props.dateRangeLabel}
            </Typography>
          )}
        {!fetchingGridData && rows !== null ? (
          <DataTableGrid columns={columns} rows={rows} options={options} />
        ) : (
          <Skeleton variant="rectangular" sx={{ height: 368 }} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AccessRuleDetailsModal;
