import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const BasicDatePicker = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <DatePicker
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        minDate={props.minDate}
        maxDate={props.maxDate}
        disableMaskedInput={true}
        disableFuture={true}
        disabled={props.disabled}
        renderInput={(params) => <TextField {...params} size="small" sx={{ maxWidth: { sm: '145px' } }} />}
      />
    </LocalizationProvider>
  );
};

export default BasicDatePicker;
