import { Navigate, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';
import Loading from '../../pages/Loading';

export const RequireAuth = (props) => {
  const { isLoading, isAuthenticated } = useAuth0();
  let location = useLocation();

  if (isLoading) {
    return <Loading />;
  }

  if (isAuthenticated) {
    return <Outlet />;
  }

  return <Navigate to="/signin" state={{ from: location }} replace />;
};
