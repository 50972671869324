import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

export default function DataFilter(props) {
  const theme = useTheme();
  const [anchor, setAnchor] = useState(null);
  const [items, setItems] = useState(props.items);
  const [filteredItems, setFilteredItems] = useState(props.items);
  const [selectedFilters, setSelectedFilters] = useState(props.selected);
  const [search, setSearch] = useState('');

  /**
   *
   * Presumably, searching should be hooked up to the API results, right?
   * That would allow you to search for a Service that isn't in the top 50.
   *
   */
  const applyFilter = (e) => {
    setSearch(e.target.value);
    setFilteredItems(items.filter((item, i) => item.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())));
  };

  const handleChange = (event) => {
    if (event.target.checked) setSelectedFilters([...selectedFilters, event.target.name]);
    else if (selectedFilters.length > 0) setSelectedFilters(selectedFilters.filter((e) => e !== event.target.name));
    else setSelectedFilters(items.filter((e) => e !== event.target.name));
  };

  const showOnly = (event) => setSelectedFilters([event.target.name]);
  const selectAll = (e) => setSelectedFilters([]);
  const deselectAll = (e) => setSelectedFilters([' ']); // assumes no results are 1 space only
  const toggleFilter = (e) => setAnchor(!anchor ? e.currentTarget : null);

  const applySelected = (e) => {
    props.onChange(selectedFilters);
    setAnchor(!anchor ? e.currentTarget : null);
  };

  const clearSearch = (e) => {
    setSearch('');
    setFilteredItems(items);
  };

  useEffect(() => {
    if (props.items) {
      setItems(props.items);
      setFilteredItems(props.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.items]);

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={toggleFilter}
        sx={{
          color: 'white',
          borderColor: 'hsl(208,8%,36%)',
          borderRadius: '4px',
          pr: 1.5,
          '& .MuiButton-endIcon': { ml: 0, mt: '-1px' },
          '&:hover': { borderColor: 'white' },
        }}
        endIcon={Boolean(anchor) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        disabled={props.disabled}>
        {props.label}
      </Button>

      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={toggleFilter}
        sx={{ mt: 0.5, '& .MuiPaper-root': { borderRadius: 0 } }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'stretch',
            backgroundColor: theme.palette.background.dark,
          }}>
          {/* search field */}
          <Box sx={{ p: 2 }}>
            <TextField
              type="text"
              placeholder="Search"
              size="small"
              fullWidth
              onChange={applyFilter}
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="primary" />
                  </InputAdornment>
                ),
                endAdornment: search && (
                  <IconButton onClick={clearSearch}>
                    <ClearIcon color="primary" />
                  </IconButton>
                ),
              }}
              sx={{
                p: 0,
                '& .MuiOutlinedInput-root': {
                  px: 0,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius: 0,
                  borderWidth: '0 0 1px 0',
                  borderColor: theme.palette.table.border,
                  p: 0,
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderWidth: '0 0 2px 0 !important',
                },
              }}
            />
          </Box>

          {/* toggles */}
          <FormControl
            size="small"
            sx={{
              maxHeight: 250,
              overflowY: 'scroll',
              flex: 1,
              '&::-webkit-scrollbar': {
                width: 20,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: theme.palette.background.dark,
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.background.paper,
                borderRadius: 1,
              },
            }}>
            <FormGroup>
              {filteredItems &&
                filteredItems.map((item, i) => (
                  <FormControlLabel
                    label={
                      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <Typography>{item}</Typography>
                        <Button
                          sx={{
                            p: 0,
                            visibility: 'hidden',
                          }}
                          name={item}
                          onClick={showOnly}>
                          Only
                        </Button>
                      </Box>
                    }
                    key={i}
                    sx={{
                      mx: 1,
                      '& .MuiFormControlLabel-label': {
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: { xs: '0.8rem', sm: '0.875rem' },
                      },
                      '&:hover .MuiButton-text': {
                        visibility: 'visible !important',
                      },
                    }}
                    control={
                      <Switch
                        checked={selectedFilters.length > 0 ? selectedFilters.includes(item) : true}
                        onChange={handleChange}
                        size="small"
                        name={item}
                        sx={{ mr: 0.5 }}
                      />
                    }
                  />
                ))}
            </FormGroup>
          </FormControl>

          {/* resets */}
          <Box sx={{ display: 'flex', gap: 1, p: 2 }}>
            <Button onClick={selectAll} sx={{ whiteSpace: 'nowrap' }}>
              Select All
            </Button>
            <Button onClick={deselectAll} sx={{ whiteSpace: 'nowrap' }}>
              None
            </Button>
            <Box sx={{ flexGrow: 1 }} />
            <Button variant="contained" disableElevation onClick={applySelected} sx={{ whiteSpace: 'nowrap' }}>
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
