import { createTheme } from '@mui/material/styles';
import base from './base';
import typography from './typography';

export const theme = createTheme(base, {
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        hasPopupIcon: {
          '.MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            paddingRight: '39px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          color: 'rgb(0, 209, 105)',
        },
      },
      variants: [
        {
          props: { variant: 'white' },
          style: {
            textTransform: 'none',
            backgroundColor: 'white',
            color: base.palette.black.main,
            gap: '0.5em',
            display: 'inline-flex',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: 'rgba(255,255,255, 0.8)',
            },
            '& [class*="MuiButton-icon"]': {
              color: base.palette.primary.main,
            },
            '& .MuiButton-startIcon': {
              marginRight: 0,
              marginLeft: '-4px',
            },
            '& .MuiButton-endIcon': {
              marginLeft: 0,
              marginRight: '-2px',
            },
            '&.MuiButton-sizeMedium': {
              fontSize: '1rem',
            },
          },
        },
        {
          props: { variant: 'primary' },
          style: {
            textTransform: 'none',
            backgroundColor: base.palette.primary.main,
            color: 'white',
            gap: '0.5em',
            display: 'inline-flex',
            alignItems: 'center',
            '&:hover': {
              backgroundColor: base.palette.primary.dark,
            },
            '& [class*="MuiButton-icon"]': {
              color: 'white',
            },
            '& .MuiButton-startIcon': {
              marginRight: 0,
              marginLeft: '-4px',
            },
            '& .MuiButton-endIcon': {
              marginLeft: 0,
              marginRight: '-2px',
            },
            '&.MuiButton-sizeMedium': {
              fontSize: '1rem',
            },
            '&.Mui-disabled': {
              backgroundColor: 'transparent',
              boxShadow: 'inset 0 0 0 1px rgba(255,255,255,0.5)',
              color: 'white',
              opacity: '.25',
              cursor: 'not-allowed',
            },
          },
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          backgroundColor: base.palette.background.dark,
        },
        standardWarning: {
          backgroundColor: base.palette.background.dark,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          'input[type="search"]::-webkit-search-cancel-button': {
            filter: 'brightness(0) invert(1)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          colorScheme: 'dark',
          '&:-webkit-autofill': {
            WebkitBoxShadow: 'none',
            transition: 'background-color 600000s 0s, color 600000s 0s',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          fontSize: '10px',
          border: `2px solid ${base.palette.background.paper}`,
          padding: '0 4px',
        },
        colorPrimary: {
          color: base.palette.neutral.main,
          backgroundColor: base.palette.primary.main,
        },
        colorSecondary: {
          color: base.palette.black.main,
          backgroundColor: base.palette.neutral.main,
        },
      },
    },
  },
  palette: base.palette,
  shape: {
    borderRadius: 8,
  },
  typography: typography,
});
