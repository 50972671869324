import * as React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTheme } from '@mui/material/styles';
import Placeholder from './Placeholder';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const ReferralsChart = (props) => {
  const theme = useTheme();
  ChartJS.defaults.font.family = theme.typography.fontFamily;

  const legendLabelsGenerator = (chart) => {
    const data = chart.data;
    const meta = chart.getDatasetMeta(0);
    if (data.labels.length && data.datasets.length) {
      const {
        labels: { color, pointStyle },
      } = chart.legend.options;

      let sum = 0;
      let dataArr = chart.data.datasets[0].data;
      dataArr.forEach((item, i) => {
        if (chart.getDataVisibility(i)) {
          sum += item;
        }
      });

      return data.labels.map((label, i) => {
        let style = meta.controller.getStyle(i);
        let pageviews = chart.data.datasets[0].data[i];
        let percentage = ((pageviews * 100) / sum).toFixed(0);
        if (percentage === '0') {
          percentage = '< 1';
        }

        return {
          text: chart.getDataVisibility(i) ? `${percentage}% ${label}` : `${label}`,
          fontColor: color,
          fillStyle: style.backgroundColor,
          strokeStyle: style.borderColor,
          lineWidth: style.borderWidth,
          pointStyle: pointStyle,
          hidden: !chart.getDataVisibility(i),
          index: i,
        };
      });
    }
    return [];
  };

  var options = {
    responsive: true,
    borderColor: theme.palette.background.paper,
    borderWidth: 2,
    layout: {
      padding: {
        left: 0,
        right: 25,
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        position: 'left',
        labels: {
          generateLabels: legendLabelsGenerator,
          boxWidth: 20,
          boxHeight: 20,
          font: {
            weight: 'bold',
          },
        },
      },
      datalabels: {
        display: 'auto',
        formatter: (value, context) => {
          let sum = 0;
          let dataArr = props.chartData.datasets[0].data;
          dataArr.forEach((item, index) => {
            if (!context.chart.legend.legendItems[index].hidden) {
              sum += item;
            }
          });
          let percentage = ((value * 100) / sum).toFixed(0) + '%';
          return percentage;
        },
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderColor: theme.palette.background.paper,
        borderRadius: 3,
        borderWidth: 2,
        padding: 5,
        align: 'center',
        anchor: 'end',
        color: '#fff',
        font: {
          size: 16,
          weight: 'bold',
        },
      },
    },
  };

  if (
    props.chartData &&
    props.chartData.datasets &&
    Object.keys(props.chartData.datasets).length > 0 &&
    props.chartData.datasets[0].data.length > 0
  ) {
    return <Pie width={0} height={280} options={options} data={props.chartData} />;
  } else {
    return (
      <Placeholder elevation={0} height={360}>
        No Data
      </Placeholder>
    );
  }
};

export default ReferralsChart;
