import * as React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Placeholder from './Placeholder';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

/**
 *
 * @props data, ar
 * @ex  <PieChart data={data} ar="4:3"></PieChart>
 * @data example: {
 *   'lineitem1': num,
 *   'lineitem2': num,
 * }
 *
 **/

const PieChart = (props) => {
  const theme = useTheme();
  ChartJS.defaults.font.family = theme.typography.fontFamily;

  const brandColors = new Map([
    ['google', '#ea4335'],
    ['twitter', '#1da1f2'],
    ['facebook', '#1877f2'],
    ['bing', '#ffb900'],
    ['yahoo', '#410093'],
    ['reddit', '#ff4500'],
    ['on-site', '#47576A'],
    ['unknown', '#47576A'],
    ['direct', '#008844'],
    ['email', '#00C563'],
    ['other', '#ea4c89'],
  ]);

  // get possible colors
  let colors = [];
  for (let color in theme.palette.chart) {
    colors.push(theme.palette.chart[color].main);
  }

  // populate datasets
  let data = {};
  data.labels = [];
  data.datasets = [];
  data.datasets[0] = {
    data: [],
    backgroundColor: [],
  };
  for (let i = 0; i < Object.keys(props.data).length; i++) {
    const label = Object.keys(props.data)[i];
    if (Object.hasOwnProperty.call(props.data, label)) {
      let value = props.data[label];
      let labelValue = Math.round(value);
      if (labelValue === 0) {
        labelValue = '< 1';
      }
      data.labels.push(labelValue + '% ' + label);
      data.datasets[0].data.push(value);

      let labelkey = label.toLowerCase().replace(/([.]\w+)$/, '');
      if (brandColors.has(labelkey)) {
        data.datasets[0].backgroundColor.push(brandColors.get(labelkey));
      } else {
        data.datasets[0].backgroundColor.push(colors[i]);
      }
    }
  }

  // determine aspect ratio
  let ar = props.ar;
  if (props.aspectratio) ar = props.aspectratio;
  ar = ar.split(':');
  ar = 100 * (ar[1] / ar[0]);

  var options = {
    responsive: true,
    maintainAspectRatio: false,
    borderColor: theme.palette.background.paper,
    borderWidth: 2,

    layout: {
      padding: {
        top: 5,
        left: 0,
        right: 25,
        bottom: 5,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.label;
          },
        },
        enabled: false,
      },
      legend: {
        position: 'left',
        labels: {
          boxWidth: 20,
          boxHeight: 20,
          color: '#fff',
          font: {
            weight: 'bold',
          },
          padding: 3,
        },
      },
      datalabels: {
        clamp: true,
        align: 'center',
        // align: 'start',
        // offset: -6,
        anchor: 'end',
        display: 'auto',
        formatter: (value) => {
          return value + '%';
        },
        backgroundColor: function (context) {
          return context.dataset.backgroundColor;
        },
        borderColor: theme.palette.background.paper,
        borderRadius: 3,
        borderWidth: 2,
        padding: 5,
        color: '#fff',
        font: {
          size: 14,
          weight: 'bold',
        },
      },
    },
  };

  if (Object.keys(props.data).length > 0) {
    return (
      <Box sx={{ position: 'relative', paddingBottom: ar + '%', height: '0', overflow: 'hidden', maxWidth: '100%' }}>
        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
          <Pie width={4} height={3} options={options} data={data} />
        </Box>
      </Box>
    );
  } else {
    return (
      <Placeholder elevation={0} height={props.height || 200}>
        No Data
      </Placeholder>
    );
  }
};

export default PieChart;
