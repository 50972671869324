import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import AccountMenu from '../AccountMenu';
import { useAuth0 } from '@auth0/auth0-react';

const PageToolbar = (props) => {
  const { isAuthenticated } = useAuth0();

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
        rowGap: '15px',
        columnGap: '5px',
        alignItems: 'center',
      }}>
      <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center', justifyContent: 'center' }}>
        <IconButton color="inherit" aria-label="open drawer" onClick={props.handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
      </Box>
      <Typography variant="pageTitle">{props.pageTitle}</Typography>
      <Box sx={{ flexGrow: 1 }} />
      {isAuthenticated && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AccountMenu />
        </Box>
      )}
    </Box>
  );
};

export default PageToolbar;
