import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import { Box, Container } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { theme } from '../theme';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 250,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const Loading = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Container maxWidth="sm">
        <ContentStyle>
          <Box sx={{ textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        </ContentStyle>
      </Container>
    </ThemeProvider>
  );
};

export default Loading;
