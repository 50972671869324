import { useEffect, useState, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, Tooltip, Title, Legend } from 'chart.js';
import * as ChartGeo from 'chartjs-chart-geo';
import DataTypeSelect from '../input/DataTypeSelect';
import { trackDetailsView } from '../../helpers/Analytics';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  ChartGeo.BubbleMapController,
  ChartGeo.ProjectionScale,
  ChartGeo.SizeScale,
  ChartGeo.ColorScale,
  ChartGeo.GeoFeature
);

const mapTypes = [
  {
    type: 'us',
    label: 'United States',
  },
  {
    type: 'world',
    label: 'World',
  },
];

const LocationsMapModal = (props) => {
  const theme = useTheme();
  const chartRef = useRef();
  const { setDisplayError } = useOutletContext();
  const [loadingMapData, setLoadingMapData] = useState(true);
  const [fetchingMapData, setFetchingMapData] = useState(false);
  const [mapData, setMapData] = useState(null);
  const [selectedMapType, setSelectedMapType] = useState(mapTypes[0].type);
  const [unitedStates, setUnitedStates] = useState(null);
  const [countries, setCountries] = useState(null);
  const [mapOutline, setMapOutline] = useState(null);
  const [projectionMethod, setProjectionMethod] = useState('albersUsa');
  const [countryCode, setCountryCode] = useState('USA');
  const [locationsLimit] = useState(100);

  const handleDetailsClose = () => {
    props.handleDetailsClose();
  };

  const handleMapChange = (event) => setSelectedMapType(event.target.value);

  const fetchMapData = (props) => {
    if (!fetchingMapData && props.apiRequestOptions) {
      setLoadingMapData(true);
      setFetchingMapData(true);

      let oParams = new URLSearchParams({
        pageSize: locationsLimit,
        sort: 'page_views',
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
        country: countryCode,
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios
        .get('/api/v1/site/page_visitor_location', oAxiosOptions)
        .then(
          (response) => {
            setMapData(response.data.data);
          },
          (error) => {
            setDisplayError(error);
          }
        )
        .finally(() => {
          setFetchingMapData(false);
          setLoadingMapData(false);
          trackDetailsView({ name: 'Map Details', widgetName: 'Top Locations' });
        });
    }
  };

  const fetchMapOutlines = () => {
    if (!unitedStates) {
      axios.get('https://unpkg.com/us-atlas/states-10m.json').then(
        (response) => {
          setUnitedStates(ChartGeo.topojson.feature(response.data, response.data.objects.states).features);
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
    if (!countries) {
      axios.get('https://unpkg.com/world-atlas/countries-50m.json').then(
        (response) => {
          setCountries(ChartGeo.topojson.feature(response.data, response.data.objects.countries).features);
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (props.detailsOpen) {
      if (!unitedStates) {
        fetchMapOutlines();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.detailsOpen]);

  useEffect(() => {
    if (selectedMapType && unitedStates && countries) {
      setLoadingMapData(true);
      if (selectedMapType === 'us') {
        setProjectionMethod('albersUsa');
        setCountryCode('USA');
        setMapOutline(unitedStates);
      } else if (selectedMapType === 'world') {
        setProjectionMethod('equalEarth');
        setCountryCode('');
        setMapOutline(countries);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMapType]);

  useEffect(() => {
    if (unitedStates) {
      setProjectionMethod('albersUsa');
      setMapOutline(unitedStates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitedStates]);

  useEffect(() => {
    if (mapOutline) {
      fetchMapData(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapOutline]);

  return (
    <Dialog
      open={props.detailsOpen}
      onClose={handleDetailsClose}
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        elevation: 1,
      }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', columnGap: 1, fontWeight: 'bold' }}>
        <Box>
          <Typography variant="h2">Pageviews by Location</Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton aria-label="close" onClick={handleDetailsClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid container alignItems="center">
            <Grid item xs={7}>
              <Typography variant="subtitle1">Top {locationsLimit} Locations</Typography>
              <Typography variant="subtitle1" sx={{ mb: { xs: 2, sm: 2 } }}>
                {props.dateRangeLabel}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                <DataTypeSelect
                  types={mapTypes}
                  type={selectedMapType}
                  onChange={handleMapChange}
                  disabled={loadingMapData}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              {!loadingMapData && mapOutline && mapData ? (
                <Box>
                  <Chart
                    ref={chartRef}
                    type="bubbleMap"
                    data={{
                      labels: mapData.map((d) => d.city),
                      datasets: [
                        {
                          outline: mapOutline,
                          showOutline: true,
                          outlineBorderColor: theme.palette.neutral.o50,
                          backgroundColor: theme.palette.chart.blue.main,
                          data: mapData.map((d) => Object.assign(d, { value: d.page_views })),
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                        datalabels: {
                          display: false,
                          align: 'top',
                          formatter: (v) => {
                            return v.city;
                          },
                        },
                      },
                      scales: {
                        xy: {
                          projection: projectionMethod,
                        },
                        r: {
                          display: false,
                        },
                      },
                      layout: {
                        padding: {
                          bottom: 8,
                        },
                      },
                    }}
                  />
                </Box>
              ) : (
                <Skeleton variant="rectangular" sx={{ height: { xs: '140px', lg: '420px' } }} />
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default LocationsMapModal;
