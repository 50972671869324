import React, { useState, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import DataTableGrid from './DataTableGrid';
import SectionTitle from '../SectionTitle';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { trackWidgetView } from '../../helpers/Analytics';
import Box from '@mui/material/Box';
import ExportButton from '../input/ExportButton';
import { useTheme } from '@mui/material/styles';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';

const TopAssetsByConversions = (props) => {
  const { setDisplayError } = useOutletContext();
  const [fetchingData, setFetchingData] = useState(false);
  const [rows, setRowData] = useState(null);
  const [totalRows, setTotalRows] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const colors = theme.palette;

  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getData = (props, page) => {
    if (props.apiRequestOptions) {
      setRowData(null);
      setFetchingData(true);
      page = page ? page : 1;

      var oParams = new URLSearchParams({
        page: page,
        pageSize: 5,
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/blox_subscription/conversions_by_content', oAxiosOptions).then(
        (response) => {
          let rowData = response.data.data.map((x, i) => ({
            ...x,
            id: i,
            headline: x.title,
            conversions: x.conversions,
          }));
          setRowData(rowData);
          setTotalRows(response.data.totalRows);
          setCurrentPage(response.data.page);
          setFetchingData(false);
          trackWidgetView({ name: 'Top Assets by Conversion Leads' });
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  const handlePageChange = (newPage) => {
    if (!fetchingData && props.domains) {
      getData(props, newPage);
    }
  };

  useEffect(() => {
    if (!fetchingData && props.domains) {
      getData(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingTopAssetsByConversions(fetchingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingData]);

  const options = {
    pageSize: 5,
    currentPage: currentPage,
    rowCount: totalRows,
    handlePageChange: handlePageChange,
    sortBy: 'conversions',
    hasNoWrapField: true,
  };

  const columns = [
    {
      field: 'id',
      maxWidth: 30,
      minWidth: 30,
      headerName: '',
      sortable: false,
      renderCell: (index) => index.api.getRowIndex(index.rowNode.id) + 1 + (currentPage - 1) * 5,
    },
    {
      field: 'headline',
      headerName: 'Headline',
      sortable: false,
      flex: 1,
      minWidth: 200,
      cellClassName: 'dontwrapme',
    },
    {
      field: 'conversions',
      headerName: 'Conversion Leads',
      sortable: false,
      flex: 0.1,
      headerAlign: 'right',
      type: 'number',
      minWidth: 170,
    },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', columnGap: 2 }}>
        <SectionTitle title="Top Assets by Conversion Leads" tooltip="Must be Audience+ customer to populate." size="sm" />
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disabled={fetchingData}
          onClick={handleMenuToggle}>
          <MoreVertOutlinedIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClick={() => setAnchorEl(null)}
          keepMounted
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            '& .MuiMenuItem-root': {
              gap: 1,
              paddingLeft: '12px',
              fontSize: '.9em',
            },
            '& .MuiSvgIcon-root': {
              fontSize: '1.25rem',
              color: colors.primary.light, 
            },
          }}>
          <MenuItem sx={{ padding: '0 !important' }}>
            <ExportButton
              variant="link"
              sx={{
                color: 'white',
                gap: 1,
                paddingLeft: '12px',
                fontSize: '.9em',
                fontWeight: 400,
              }}
              {...props}
              modal={true}
              export={{
                api: '/api/v1/blox_subscription/conversions_by_content',
                name: 'top-assets',
                params: {},
              }}
            />
          </MenuItem>
        </Menu>
      </Box>
      {rows !== null ? (
        <DataTableGrid columns={columns} rows={rows} options={options} />
      ) : (
        <Skeleton variant="rectangular" height={500} />
      )}
    </>
  );
};

export default TopAssetsByConversions;
