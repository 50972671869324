import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider'

function ConfirmationModal({ cancelButtonText = 'Cancel', confirmButtonText = 'Confirm', confirmComponent, confirmText, disableCancelButton, disableConfirmButton, headerText, onCancel, onClose, onConfirm, open }) {

  async function handleCancel() {
    let response = null;
    if (onCancel) response = await onCancel();
    handleModalClose(response);
  }
  async function handleConfirm() {
    let response = null
    if (onConfirm) response = await onConfirm();
    handleModalClose(response);
  }
  function handleModalClose(data) {
    if (onClose) onClose(data);
  }

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        elevation: 1,
      }}
    >
      <DialogTitle sx={{display: 'flex', alignItems: 'center', columnGap: 1}}>
        {headerText}
      </DialogTitle>
      <Divider />
      <DialogContent>
        {confirmText}
        {confirmComponent}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleCancel} disabled={disableCancelButton}>{cancelButtonText}</Button>
        <Button onClick={handleConfirm} disabled={disableConfirmButton}>{confirmButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
}
export default ConfirmationModal;
