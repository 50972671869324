import * as React from 'react';
import { useEffect, useState } from 'react';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import { useOutletContext } from 'react-router-dom';
import ExportModal from '../modals/ExportModal';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

export default function ExportButton(props) {
  const theme = useTheme();
  const colors = theme.palette;
  const { setDisplayError } = useOutletContext();
  const [exportName, setExportName] = useState('export');
  const [fetchingExport, setFetchingExport] = useState(false);
  const [downloadFile, setDownloadFile] = useState(null);
  const [showDownloadBtn, setShowDownloadBtn] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const handleOpenExportModal = () => setExportModalOpen(true);
  const handleCloseExportModal = () => setExportModalOpen(false);

  const handleFileDownload = () => {
    const link = document.createElement('a');
    link.href = downloadFile;
    link.setAttribute('download', exportName + '.csv');
    link.click();
  };

  const handleExportClick = () => {
    if (props.modal) handleOpenExportModal();
    else {
      setFetchingExport(true);
      setShowDownloadBtn(true);

      // defaults
      var oParams = new URLSearchParams({
        page: 1,
        hostname: props.hostname ? props.hostname : props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
        export: 'csv',
        exportFileName: exportName,
      });

      // api specific
      Object.entries(props.export.params).forEach((item) => {
        if (item[0] !== 'localOnly') oParams.append(item[0], item[1]);
      });
      if (props.export.params.localOnly === true) oParams.append('localOnly', true);

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        responseType: 'blob',
        params: oParams,
      };

      axios.get(props.export.api, oAxiosOptions).then(
        (response) => {
          let url = window.URL.createObjectURL(new Blob([response.data]));

          setDownloadFile(url);
          setFetchingExport(false);
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  useEffect(() => {
    setShowDownloadBtn(false);
    let exportFileName = `${props.export.name}--${props.startDate.toISODate().replace(/-/g, '')}-${props.endDate
      .toISODate()
      .replace(/-/g, '')}`;
      if (props.export.params && props.export.params.localOnly) exportFileName += '__localonly';

    setExportName(exportFileName);
  }, [props.export.name, props.export.params, props.startDate, props.endDate]);

  return (
    <div>
      {showDownloadBtn ? (
        fetchingExport ? (
          <Button size={props.size ? props.size : 'small'} variant="primary" disabled sx={{ fontSize: '0.8125rem' }}>
            <CircularProgress sx={{ width: '1.25em !important', height: '1.25em !important' }} />
            Building...
          </Button>
        ) : (
          <Button
            size={props.size ? props.size : 'small'}
            variant="contained"
            color="primary"
            onClick={handleFileDownload}
            sx={{ lineHeight: 1 }}>
            <FileDownloadOutlinedIcon sx={{ paddingRight: 0.5 }} />
            Download
          </Button>
        )
      ) : (
        <Box>
          {!props.iconOnly ? (
            <Button
              onClick={handleExportClick}
              size={props.size ? props.size : 'small'}
              variant={props.variant ? props.variant : 'text'}
              sx={
                props.sx
                  ? props.sx
                  : {
                      gap: 0.25,
                      paddingLeft: '12px',
                      fontSize: '14px',
                      color: 'white',
                      fontWeight: 400,
                      alignItems: 'center',
                      '& .MuiSvgIcon-root': {
                        fontSize: '1.25rem',
                        color: colors.primary.light, // colors.secondary.light,
                      },
                    }
              }>
              <FileDownloadOutlinedIcon />
              Export CSV
            </Button>
          ) : (
            <IconButton onClick={handleExportClick}>
              <FileDownloadOutlinedIcon color="primary" />
            </IconButton>
          )}
        </Box>
      )}
      <ExportModal exportModalOpen={exportModalOpen} handleCloseExportModal={handleCloseExportModal} {...props} />
    </div>
  );
}
