import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useAuth0 } from '@auth0/auth0-react';

const TrendingToolbarFiltersModal = (props) => {
  const { isAuthenticated } = useAuth0();
  const [selectedSection, setSelectedSection] = useState(props.selectedSection || '');
  const [initialSection, setInitialSection] = useState(props.selectedSection || '');
  const [selectedAuthor, setSelectedAuthor] = useState(props.selectedAuthor || '');
  const [initialAuthor, setInitialAuthor] = useState(props.selectedAuthor || '');
  const [authorError, setAuthorError] = useState(false);
  const [authorHelperText, setAuthorHelperText] = useState('');
  const [selectedContentTypes, setSelectedContentTypes] = useState(
    props.selectedContentTypes || [{ id: 'article', label: 'Article' }]
  );
  const [initialContentTypes, setInitialContentTypes] = useState(
    props.selectedContentTypes || [{ id: 'article', label: 'Article' }]
  );
  const [selectedSource, setSelectedSource] = useState(props.selectedSource || '');
  const [initialSource, setInitialSource] = useState(props.selectedSource || '');

  const sections = ['/news', '/news/local', '/sports', '/opinion', '/entertainment', '/lifestyles'];

  const contentTypes = [
    { id: 'article', label: 'Articles' },
    { id: 'video', label: 'Videos' },
    { id: 'collection', label: 'Collections' },
    { id: 'image', label: 'Images' },
    { id: 'poll', label: 'Polls' },
    { id: 'html', label: 'HTML' },
    { id: 'page', label: 'E-edition' },
  ];

  const sources = [
    { id: '(direct)', label: 'Direct' },
    { id: 'on-site', label: 'On-site' },
    { id: 'google', label: 'Google' },
    { id: 'twitter', label: 'Twitter' },
    { id: 'facebook', label: 'Facebook' },
    { id: 'bing', label: 'Bing' },
    { id: 'reddit', label: 'Reddit' },
    { id: 'yahoo', label: 'Yahoo' },
    { id: 'email', label: 'Email' },
  ];

  const handleSectionChange = (event, newValue) => {
    setSelectedSection(newValue);
  };

  const handleAuthorChange = (event) => {
    setSelectedAuthor(event.target.value);
  };

  const handleContentTypeChange = (event, newValue) => {
    setSelectedContentTypes(newValue);
  };

  const handleSourceChange = (event, newValue) => {
    newValue ? setSelectedSource(newValue.id) : setSelectedSource('');
  };

  const handleCancel = (event, newValue) => {
    setSelectedSection(initialSection);
    setSelectedAuthor(initialAuthor);
    setSelectedContentTypes(initialContentTypes);
    setSelectedSource(initialSource);
    handleFiltersClose();
  };

  const handleFiltersClose = () => {
    setAuthorError(false);
    setAuthorHelperText('');
    props.handleFiltersClose();
  };

  const applyFilters = () => {
    if (selectedAuthor && !isEmail(selectedAuthor)) {
      setAuthorError(true);
      setAuthorHelperText('Invalid email address');
    } else {
      props.applyFilters();
      handleFiltersClose();
    }
  };

  const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  useEffect(() => {
    if (selectedSection || typeof selectedSection === 'string') {
      props.handleSectionChange(selectedSection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection]);

  useEffect(() => {
    if (selectedAuthor || typeof selectedAuthor === 'string') {
      props.handleAuthorChange(selectedAuthor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAuthor]);

  useEffect(() => {
    if (JSON.stringify(selectedContentTypes) !== JSON.stringify(initialContentTypes)) {
      props.handleContentTypeChange(selectedContentTypes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContentTypes]);

  useEffect(() => {
    if (selectedSource || typeof selectedSource === 'string') {
      props.handleSourceChange(selectedSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSource]);

  useEffect(() => {
    if (props.selectedSection) {
      setSelectedSection(props.selectedSection);
    }
  }, [props.selectedSection]);

  useEffect(() => {
    if (props.selectedAuthor) {
      setSelectedAuthor(props.selectedAuthor);
    }
  }, [props.selectedAuthor]);

  useEffect(() => {
    if (props.selectedContentTypes) {
      setSelectedContentTypes(props.selectedContentTypes);
    }
  }, [props.selectedContentTypes]);

  useEffect(() => {
    if (props.selectedSource) {
      setSelectedSource(props.selectedSource);
    }
  }, [props.selectedSource]);

  useEffect(() => {
    if (props.filtersOpen) {
      setInitialSection(selectedSection);
      setInitialAuthor(selectedAuthor);
      setInitialContentTypes(selectedContentTypes);
      setInitialSource(selectedSource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filtersOpen]);

  return (
    <Dialog
      open={props.filtersOpen}
      onClose={handleFiltersClose}
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{
        elevation: 1,
      }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'flex-start', columnGap: 1, fontWeight: 'bold' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'baseline' }}>
          <Typography variant="h2" gutterBottom sx={{ m: 0, mr: 1 }}>
            Filters
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton aria-label="close" onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 0.75 }}>
          <Autocomplete
            id="section-select"
            freeSolo
            size="small"
            options={sections}
            value={selectedSection}
            readOnly={!isAuthenticated}
            onInputChange={handleSectionChange}
            disabled={props.disabled || !isAuthenticated}
            renderInput={(params) => (
              <TextField {...params} label="Section" placeholder="All sections" InputLabelProps={{ shrink: true }} />
            )}
          />
          <TextField
            id="author-field"
            type="email"
            size="small"
            label="Author email address"
            placeholder="All authors"
            error={authorError}
            helperText={authorHelperText}
            value={selectedAuthor}
            onChange={handleAuthorChange}
            disabled={props.disabled || !isAuthenticated}
            InputLabelProps={{ shrink: true }}
          />
          <Autocomplete
            multiple
            limitTags={1}
            id="content-type-select"
            size="small"
            options={contentTypes}
            readOnly={!isAuthenticated}
            defaultValue={[contentTypes[0]]}
            value={selectedContentTypes}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleContentTypeChange}
            disabled={props.disabled || !isAuthenticated}
            renderInput={(params) => <TextField {...params} label="Content Type" />}
          />
          <Autocomplete
            id="source-select"
            freeSolo
            size="small"
            options={sources}
            value={selectedSource}
            isOptionEqualToValue={(option, value) => option.id === value}
            readOnly={!isAuthenticated}
            onChange={handleSourceChange}
            disabled={props.disabled || !isAuthenticated}
            renderInput={(params) => (
              <TextField {...params} label="Traffic Source" placeholder="All sources" InputLabelProps={{ shrink: true }} />
            )}
          />
          <Stack direction="row" spacing={1} justifyContent="center">
            <Button onClick={handleCancel} variant="link">
              Cancel
            </Button>
            <Button onClick={applyFilters} variant="contained">
              Apply
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TrendingToolbarFiltersModal;
