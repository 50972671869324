import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': {
    fontSize: 14,
  },
}));

export default function LocalOnlyToggle(props) {
  return (
    <StyledFormControlLabel
      control={<Switch size="small" onChange={props.onChange} />}
      label={
        <Tooltip title="Show local content only." placement="top" arrow enterTouchDelay={0}>
          <Box>Local</Box>
        </Tooltip>
      }
      disabled={props.disabled}
    />
  );
}
