import {useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import axios from 'axios';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import ConfirmationModal from './ConfirmationModal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const defaultHelperText = 'To send multiple invitations enter email addresses separated by a semicolon (eg: test@gmail.com;test2@gmail.com;test3@gmail.com;etc.). Note: email addresses that are already pending will not be sent.';
const defaultInviteResponseInfo = {
  message: '',
  open: false,
  severity: 'error'
};

function InviteModal({handleClose, open}) {
  const {getAccessTokenSilently} = useAuth0();
  const [accessToken, setAccessToken] = useState('');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState('');
  const [hasError, setHasError] = useState(false);
  const [helperText, setHelperText] = useState(defaultHelperText);
  const [inviteResponseInfo, setInviteResponseInfo] = useState(defaultInviteResponseInfo);
  const [loadingTimeoutComplete, setLoadingTimeoutComplete] = useState(false);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [pendingInvitesPage, setPendingInvitesPage] = useState(1);
  const [retrieveAccessTokenAttempted, setRetrieveAccessTokenAttempted] = useState(false);
  const [retrievePendingInvitesAttempted, setRetrievePendingInvitesAttempted] = useState(false);
  const [retrieveRolesAttempted, setRetrieveRolesAttempted] = useState(false);
  const [roles, setRoles] = useState([]);
  const [sendingInvites, setSendingInvites] = useState(false);
  const [validEmailAddresses, setValidEmailAddresses] = useState(new Set());

  const maxPendingInvitesPerPage = 10;

  useEffect(() => {
    setTimeout(() => {
      setLoadingTimeoutComplete(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!open) return;
    let newLoadingTimeoutComplete = loadingTimeoutComplete;
    if (!accessToken) {
      setRetrieveAccessTokenAttempted(false);
      newLoadingTimeoutComplete = false;
    }
    if (!roles.length) {
      setRetrieveRolesAttempted(false);
      newLoadingTimeoutComplete = false;
    }
    if (!newLoadingTimeoutComplete) {
      setLoadingTimeoutComplete(false);
      setTimeout(() => {
        setLoadingTimeoutComplete(true);
      }, 1000);
    }
  }, [accessToken, loadingTimeoutComplete, open, roles]);

  useEffect(() => {
    if (accessToken || retrieveAccessTokenAttempted) return;
    (async () => {
      try {
        const newAccessToken = await getAccessTokenSilently()
        setAccessToken(newAccessToken ?? '');
      } catch(err) {
        setInviteResponseInfo({
          message: `Error retrieving access token: ${err.message}`,
          open: true,
          severity: 'error'
        });
      }
      setRetrieveAccessTokenAttempted(true);
    })();
  }, [accessToken, getAccessTokenSilently, retrieveAccessTokenAttempted]);

  useEffect(() => {
    if (!accessToken || !open) return;
    (async () => {
      try {
        const newPendingInvites = await axios.get('/api/v1/user/invite', {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        });
        setPendingInvites(newPendingInvites?.data?.map(pendingInvite => pendingInvite?.invitee?.email ?? '').filter(email => !!email).sort());
      } catch(err) {
        setInviteResponseInfo({
          message: `Error retrieving pending invitations: ${err.response?.data?.message ?? err.message}`,
          open: true,
          severity: 'error'
        });
      }
      setRetrievePendingInvitesAttempted(true);
    })();
  }, [accessToken, open])

  useEffect(() => {
    if (!accessToken || roles.length || retrieveRolesAttempted) return;
    (async () => {
      try {
        const response = await axios.get('/api/v1/user/roles', {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        });
        setRoles(response?.data?.length ? response.data : []);
      } catch(err) {
        setInviteResponseInfo({
          message: `Error retrieving roles: ${err.response?.data?.message ?? err.message}`,
          open: true,
          severity: 'error'
        });
      }
      setRetrieveRolesAttempted(true);
    })();
  }, [accessToken, retrieveRolesAttempted, roles]);

  useEffect(() => {
    if (inviteResponseInfo.open) {
      setTimeout(() => {
        setInviteResponseInfo(defaultInviteResponseInfo);
      }, 5000);
    }
  }, [inviteResponseInfo.open])

  function handleChange(e) {
    setEmailAddresses(e.target.value);
    setHasError(false);
    setHelperText(defaultHelperText);
    setValidEmailAddresses(new Set());
  }

  function handleModalClose() {
    setConfirmModalOpen(false);
    setEmailAddresses('');
    setHasError(false);
    setHelperText(defaultHelperText);
    setPendingInvites([]);
    setPendingInvitesPage(1);
    setRetrievePendingInvitesAttempted(false);
    setValidEmailAddresses(new Set());
    if (inviteResponseInfo.type) {
      setTimeout(() => {
        setInviteResponseInfo(defaultInviteResponseInfo);
        if (handleClose) handleClose();
      }, 2000);
    } else if (handleClose) handleClose();
  }

  async function handleSendInvitations() {
    if (!accessToken || !roles.length || !validEmailAddresses.size) {
      setInviteResponseInfo({
        message: 'Access token, roles, and at least one valid email address needed to send invitations.',
        open: true,
        severity: 'error'
      });
      handleModalClose();
      return;
    }

    try {
      setSendingInvites(true);
      const response = await axios.post('/api/v1/user/invite', Array.from(validEmailAddresses).map(emailAddress => ({
        email: emailAddress,
        roles: roles.map(role => role.id)
      })), {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      setInviteResponseInfo({
        message: response?.data?.recipients?.length ? `Invitation(s) sent to: ${response?.data?.recipients?.join(', ')}` : 'No invitations sent.',
        open: true,
        severity: 'success'
      });
    } catch(err) {
      setInviteResponseInfo({
        message: `Error sending invitations: ${err.response?.data?.message ?? err.message}`,
        open: true,
        severity: 'error'
      });
    }

    setSendingInvites(false);
    setConfirmModalOpen(false);
    handleModalClose();
  }

  function handleSubmit(e) {
    e.preventDefault();

    const parsedEmailAddresses = new Set(emailAddresses.split(';').map(emailAddress => emailAddress.toLowerCase().trim()).filter(emailAddress => emailAddress && validateEmail(emailAddress) && !pendingInvites.includes(emailAddress)));

    if (!parsedEmailAddresses.size) {
      setHasError(true);
      setHelperText('You must include at least one valid email address to which you have not already sent an invitation to be able to send invitations.');
      setValidEmailAddresses(new Set());
      return;
    }

    setHasError(false);
    setValidEmailAddresses(parsedEmailAddresses);
    setConfirmModalOpen(true);
  }

  function validateEmail(email) {
    return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[-d]{1,3}\.[-d]{1,3}\.[-d]{1,3}\.[-d]{1,3}])|(([a-zA-Z\--d]+\.)+[a-zA-Z]{2,}))$/);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleModalClose}
        fullWidth={true}
        maxWidth="sm"
        PaperProps={{
          elevation: 1,
        }}
      >
        <DialogTitle sx={{display: 'flex', alignItems: 'center', columnGap: 1}}>
          <GroupAddIcon/> Send invitations to dashboard
        </DialogTitle>
        <DialogContent>
          {loadingTimeoutComplete ? (
            accessToken && roles.length > 0 ? (
              <form onSubmit={handleSubmit}>
                <Typography variant="h3" sx={{marginBottom: '10px'}}>New Invitations:</Typography>
                <TextField
                  error={hasError}
                  label="Enter email address(es)"
                  helperText={helperText}
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={emailAddresses}
                  onChange={handleChange}
                  InputProps={{endAdornment: <Button onClick={handleSubmit}>Submit</Button>}}
                />
              </form>
            ) : <div>No access token or roles found. Please try again later.</div>
          ) : <Skeleton variant="rectangular" height={65} sx={{mb: '1px'}}/>}

          <br/>
          <Divider/>
          <br/>

          <div>
            <Typography variant="h3" sx={{marginBottom: '10px'}}>Pending Invitations:</Typography>
            {retrievePendingInvitesAttempted ? (
              <div>
                {!pendingInvites.length && <Typography>None</Typography>}
                <div>
                  {pendingInvites.slice((pendingInvitesPage - 1) * maxPendingInvitesPerPage, maxPendingInvitesPerPage * pendingInvitesPage).map((pendingInvite, index) => <Typography
                    key={index} sx={{marginLeft: '10px'}}
                  >{pendingInvite}</Typography>)}
                  <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '10px'}}>
                    {pendingInvites.length > maxPendingInvitesPerPage && <Pagination count={Math.ceil(pendingInvites.length / maxPendingInvitesPerPage)} page={pendingInvitesPage} onChange={(e, value) => setPendingInvitesPage(value)} />}
                  </div>
                </div>
              </div>
            ) : <Skeleton variant="rectangular" height={30}/>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Done</Button>
        </DialogActions>
      </Dialog>

      <ConfirmationModal
        confirmText={Array.from(validEmailAddresses).map(emailAddress => <Typography
          key={emailAddress.toString()} variant="subtitle1"
        >{emailAddress}</Typography>)}
        disableCancelButton={sendingInvites}
        disableConfirmButton={sendingInvites}
        headerText="Are you sure you want to send invitations to:"
        onCancel={() => setConfirmModalOpen(false)}
        onConfirm={handleSendInvitations}
        open={confirmModalOpen}
      />

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}
        open={inviteResponseInfo.open}
        message={inviteResponseInfo.message}
      >
        <Alert
          elevation={3}
          onClose={() => setInviteResponseInfo(defaultInviteResponseInfo)}
          severity={inviteResponseInfo.severity}
        >
          {inviteResponseInfo.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default InviteModal;
