import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function SectionTitle(props) {
  let tooltip = props.tooltip || props.tip;
  let variant = props.size || props.variant;
  if (!variant.includes('header_')) variant = 'header_' + variant;
  return (
    <Typography variant={variant} gutterBottom sx={{ mb: 0, pb: 1, alignItems: 'center' }}>
      {props.title}
      {tooltip && (
        <Tooltip title={tooltip} placement="top" arrow enterTouchDelay={0}>
          <HelpOutlineIcon sx={{ ml: 0.5, top: 2, position: 'relative', fontSize: '1rem', verticalAlign: 'middle' }} />
        </Tooltip>
      )}
      {props.label && <Chip label={props.label} size="small" sx={{ ml: 1, fontWeight: '400' }} />}
    </Typography>
  );
}
