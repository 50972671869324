import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import BarChart from './BarChart';

const DevicesChart = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [chartData, setChartData] = useState(null);
  const [fetchingChartData, setfetchingChartData] = useState(false);
  const [loadingChart, setLoadingChart] = useState(true);

  const formatChartData = (data) => {
    return data.reduce((acc, cur) => ({ ...acc, [cur.label]: cur.page_views }), {});
  };

  const fetchChartData = () => {
    if (props.apiRequestOptions) {
      setLoadingChart(true);
      setfetchingChartData(true);

      var oParams = new URLSearchParams({
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/site/devices', oAxiosOptions).then(
        (response) => {
          setChartData(formatChartData(response.data.data));
          setfetchingChartData(false);
          setLoadingChart(false);
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingChartData && props.domains) {
      fetchChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingDevicesChart(fetchingChartData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingChartData]);

  return (
    <Box sx={{ maxWidth: { sm: '50vw' }, mx: 'auto' }}>
      {!loadingChart && chartData ? (
        <BarChart maxticks="3" data={chartData} type="horizontal" labelx="Pageviews" formatx="compact"></BarChart>
      ) : (
        <Skeleton variant="rectangular" height={200} />
      )}
    </Box>
  );
};

export default DevicesChart;
