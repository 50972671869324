import ReactGA from 'react-ga4';

const trackingId = process.env.REACT_APP_GA4_ID;
const isDebug = process.env.REACT_APP_GA4_DEBUG_MODE === 'true';
const isEnabled = process.env.NODE_ENV === 'production' || isDebug;

export const initAnalytics = () => {
  if (isEnabled) {
    let options = { send_page_view: true };
    if (isDebug) {
      options.debug_mode = true;
    }
    ReactGA.initialize([{ trackingId: trackingId, gtagOptions: options }]);
  }
};

export const trackWidgetView = ({ name, sort, local, filter, autoUpdate, fullscreen }) => {
  if (isEnabled) {
    let options = {
      widget_name: name,
      ...(typeof local !== 'undefined' && { local: local }),
      ...(typeof sort !== 'undefined' && { sort: sort }),
      ...(typeof filter !== 'undefined' && filter && { filter: filter }),
      ...(typeof autoUpdate !== 'undefined' && { auto_refresh: autoUpdate }),
      ...(typeof fullscreen !== 'undefined' && { fullscreen: fullscreen }),
    };
    ReactGA.event('widget_view', options);
  }
};

export const trackDetailsView = ({ name, widgetName }) => {
  if (isEnabled) {
    let options = {
      details_name: name,
      widget_name: widgetName,
    };
    ReactGA.event('details_view', options);
  }
};
