import React, { useState, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import DataTableGrid from './DataTableGrid';
import DataTypeSelect from '../input/DataTypeSelect';
import LocalOnlyToggle from '../input/LocalOnlyToggle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { trackWidgetView } from '../../helpers/Analytics';
import ExportButton from '../input/ExportButton';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const TopSections = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [fetchingData, setFetchingData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const colors = theme.palette;

  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const datatypes = [
    {
      type: 'page_views',
      label: 'By Pageviews',
    },
    {
      type: 'content_items',
      label: 'By Assets',
    },
  ];

  const [datatype, setDataType] = useState(datatypes[0].type);
  const [rows, setRowData] = useState(null);
  const [totalRows, setTotalRows] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [localOnly, setLocalOnly] = useState(false);

  const getData = (props, page) => {
    if (props.apiRequestOptions) {
      setRowData(null);
      setFetchingData(true);
      page = page ? page : 1;

      var oParams = new URLSearchParams({
        page: page,
        pageSize: 5,
        sort: datatype,
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      if (localOnly) {
        oParams.append('localOnly', localOnly);
      }

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/site/categories', oAxiosOptions).then(
        (response) => {
          let rowData = response.data.data.map((x, i) => ({
            ...x,
            url: x.id,
            id: i,
          }));
          setRowData(rowData);
          setTotalRows(response.data.totalRows);
          setCurrentPage(response.data.page);
          setFetchingData(false);
          trackWidgetView({ name: 'Top Sections', sort: datatype, local: localOnly });
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  const handleDataTypeChange = (event) => {
    setDataType(event.target.value);
  };

  const handleLocalChange = (event) => {
    setLocalOnly(event.target.checked);
  };

  const handlePageChange = (newPage) => {
    if (filtersReady && !fetchingData && props.domains) {
      getData(props, newPage);
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingData && props.domains) {
      getData(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate, datatype, localOnly]);

  useEffect(() => {
    props.setLoadingTopSections(fetchingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingData]);

  const options = {
    pageSize: 5,
    currentPage: currentPage,
    rowCount: totalRows,
    handlePageChange: handlePageChange,
    sortBy: datatype,
  };

  const columns = [
    {
      field: 'id',
      maxWidth: 30,
      minWidth: 30,
      headerName: '',
      sortable: false,
      renderCell: (index) => index.api.getRowIndex(index.rowNode.id) + 1 + (currentPage - 1) * 5,
    },
    {
      field: 'label',
      headerName: 'Section',
      sortable: false,
      flex: 1,
      minWidth: 120,
      //   cellClassName: 'dontwrapme',
    },
    {
      field: 'url',
      headerName: 'URL',
      sortable: false,
      flex: 1,
      minWidth: 120,
      headerAlign: 'right',
      align: 'right',
      //   cellClassName: 'dontwrapme',
    },
    {
      field: 'page_views',
      headerName: 'Pageviews',
      sortable: false,
      flex: 0.1,
      headerAlign: 'right',
      type: 'number',
      minWidth: 120,
      renderHeader: (params) => (
        <Box className="MuiDataGrid-columnHeaderTitle" sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          {datatype === 'page_views' && <ArrowDownwardIcon sx={{ fontSize: '1.225rem' }} />} {params.colDef.headerName}
        </Box>
      ),
    },
    {
      field: 'content_items',
      headerName: 'Assets',
      sortable: false,
      flex: 0.1,
      headerAlign: 'right',
      type: 'number',
      minWidth: 100,
      renderHeader: (params) => (
        <Box className="MuiDataGrid-columnHeaderTitle" sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
          {datatype === 'content_items' && <ArrowDownwardIcon sx={{ fontSize: '1.225rem' }} />} {params.colDef.headerName}
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'baseline', justifyContent: 'space-between' }}>
        <Typography variant="h2" gutterBottom sx={{ mr: 1 }}>
          Top Sections
        </Typography>
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'right', width: { xs: '100%', sm: 'auto' } }}>
          <LocalOnlyToggle onChange={handleLocalChange} disabled={fetchingData} />
          <DataTypeSelect types={datatypes} type={datatype} onChange={handleDataTypeChange} disabled={fetchingData} />
          <IconButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            disabled={fetchingData}
            onClick={handleMenuToggle}>
            <MoreVertOutlinedIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClick={() => setAnchorEl(null)}
            keepMounted
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              '& .MuiMenuItem-root': {
                gap: 1,
                paddingLeft: '12px',
                fontSize: '.9em',
              },
              '& .MuiSvgIcon-root': {
                fontSize: '1.25rem',
                color: colors.primary.light, // colors.secondary.light,
              },
            }}>
            <MenuItem sx={{ padding: '0 !important' }}>
              <ExportButton
                // size="small"
                variant="link"
                sx={{
                  color: 'white',
                  gap: 1,
                  paddingLeft: '12px',
                  fontSize: '.9em',
                  fontWeight: 400,
                }}
                {...props}
                modal={true}
                export={{
                  api: '/api/v1/site/categories',
                  name: 'top-sections',
                  params: {
                    sort: datatype,
                    localOnly: localOnly,
                  },
                }}
              />
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      {rows !== null ? (
        <DataTableGrid columns={columns} rows={rows} options={options} />
      ) : (
        <Skeleton variant="rectangular" height={340} sx={{ mt: 2 }} />
      )}
    </>
  );
};

export default TopSections;
