import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useAuth0 } from '@auth0/auth0-react';

const OverallFiltersModal = (props) => {
  const { isAuthenticated } = useAuth0();
  const [selectedSection, setSelectedSection] = useState(props.selectedSection || '');
  const [initialSection, setInitialSection] = useState(props.selectedSection || '');
  const [selectedContentTypes, setSelectedContentTypes] = useState(props.selectedContentTypes || []);
  const [initialContentTypes, setInitialContentTypes] = useState(props.selectedContentTypes || []);

  const sections = ['/news', '/news/local', '/sports', '/opinion', '/entertainment', '/lifestyles'];

  const contentTypes = [
    { id: 'article', label: 'Articles' },
    { id: 'video', label: 'Videos' },
    { id: 'collection', label: 'Collections' },
    { id: 'image', label: 'Images' },
    { id: 'poll', label: 'Polls' },
    { id: 'html', label: 'HTML' },
    { id: 'page', label: 'E-edition' },
  ];

  const handleSectionChange = (event, newValue) => {
    setSelectedSection(newValue);
  };

  const handleContentTypeChange = (event, newValue) => {
    setSelectedContentTypes(newValue);
  };

  const handleCancel = (event, newValue) => {
    setSelectedSection(initialSection);
    setSelectedContentTypes(initialContentTypes);
    handleFiltersClose();
  };

  const handleFiltersClose = () => {
    props.handleFiltersClose();
  };

  const applyFilters = () => {
    props.applyFilters(selectedSection, selectedContentTypes);
    handleFiltersClose();
  };

  useEffect(() => {
    if (props.filtersOpen) {
      setInitialSection(selectedSection);
      setInitialContentTypes(selectedContentTypes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.filtersOpen]);

  return (
    <Dialog
      open={props.filtersOpen}
      onClose={handleFiltersClose}
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{
        elevation: 1,
      }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'flex-start', columnGap: 1, fontWeight: 'bold' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignItems: 'baseline' }}>
          <Typography variant="h2" gutterBottom sx={{ m: 0, mr: 1 }}>
            Filters
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton aria-label="close" onClick={handleCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ mt: 0.75 }}>
          <Autocomplete
            id="section-select"
            freeSolo
            size="small"
            options={sections}
            value={selectedSection}
            readOnly={!isAuthenticated}
            onInputChange={handleSectionChange}
            disabled={props.disabled || !isAuthenticated}
            renderInput={(params) => (
              <TextField {...params} label="Section" placeholder="All sections" InputLabelProps={{ shrink: true }} />
            )}
          />
          <Autocomplete
            multiple
            limitTags={1}
            id="content-type-select"
            size="small"
            options={contentTypes}
            readOnly={!isAuthenticated}
            defaultValue={[contentTypes[0]]}
            value={selectedContentTypes}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={handleContentTypeChange}
            disabled={props.disabled || !isAuthenticated}
            renderInput={(params) => <TextField {...params} label="Content Type" />}
          />
          <Stack direction="row" spacing={1} justifyContent="center">
            <Button onClick={handleCancel} variant="link">
              Cancel
            </Button>
            <Button onClick={applyFilters} variant="contained">
              Apply
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default OverallFiltersModal;
