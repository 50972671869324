import React, { useState, useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import DataTableGrid from './DataTableGrid';
import LocationsMapModal from '../modals/LocationsMapModal';
import { trackWidgetView } from '../../helpers/Analytics';

function getLocation(params) {
  return params.row.city === '(not set)' ? params.row.region : params.row.city;
}

const TopLocations = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [fetchingData, setFetchingData] = useState(false);
  const [rows, setRowData] = useState(null);
  const [totalRows, setTotalRows] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [detailsOpen, setDetailsOpen] = useState(false);

  const handleDetailsOpen = () => {
    setDetailsOpen(true);
  };
  const handleDetailsClose = () => setDetailsOpen(false);

  const getData = (props, page) => {
    if (props.apiRequestOptions) {
      setRowData(null);
      setFetchingData(true);
      page = page ? page : 1;

      var oParams = new URLSearchParams({
        page: page,
        pageSize: 5,
        sort: 'page_views',
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/site/page_visitor_location', oAxiosOptions).then(
        (response) => {
          setRowData(response.data.data);
          setTotalRows(response.data.totalRows);
          setCurrentPage(response.data.page);
          setFetchingData(false);
          trackWidgetView({ name: 'Top Locations' });
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  const handlePageChange = (newPage) => {
    if (filtersReady && !fetchingData && props.domains) {
      getData(props, newPage);
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingData && props.domains) {
      getData(props);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingTopLocations(fetchingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingData]);

  const options = {
    pageSize: 5,
    currentPage: currentPage,
    rowCount: totalRows,
    handlePageChange: handlePageChange,
    sortBy: 'pageviews',
    approxHeight: 300, // useful for skeleton element while data is loading
    hideFooter: true,
  };

  const columns = [
    {
      field: 'id',
      maxWidth: 30,
      minWidth: 30,
      headerName: '',
      sortable: false,
      renderCell: (index) => index.api.getRowIndex(index.rowNode.id) + 1 + (currentPage - 1) * 5,
    },
    {
      field: 'city',
      headerName: 'Location',
      sortable: false,
      flex: 1,
      valueGetter: getLocation,
      // width: 160,
    },
    {
      field: 'page_views',
      headerName: 'Pageviews',
      sortable: false,
      flex: 1,
      headerAlign: 'right',
      type: 'number',
      // width: 160,
    },
  ];

  return (
    <>
      <Typography variant="h2" gutterBottom sx={{ mr: 1 }}>
        Top Locations
      </Typography>
      {rows !== null ? (
        <>
          <DataTableGrid columns={columns} rows={rows} options={options} />
          <Box sx={{ textAlign: 'center', my: 1 }}>
            <Button onClick={handleDetailsOpen} size="small" variant="outlined" color="neutral">
              View Map
            </Button>
          </Box>
        </>
      ) : (
        <Skeleton variant="rectangular" height={300} />
      )}
      <LocationsMapModal
        detailsOpen={detailsOpen}
        handleDetailsClose={handleDetailsClose}
        domains={props.domains}
        startDate={props.startDate}
        endDate={props.endDate}
        dateRangeLabel={props.dateRangeLabel}
        apiRequestOptions={props.apiRequestOptions}
      />
    </>
  );
};

export default TopLocations;
