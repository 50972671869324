import * as React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const AreaChart = (props) => {
  const theme = useTheme();
  ChartJS.defaults.font.family = theme.typography.fontFamily;
  ChartJS.defaults.color = theme.palette.text.primary;
  ChartJS.defaults.borderColor = theme.palette.table.border;

  let data = {};
  data.labels = Object.keys(props.data);
  data.datasets = [];
  data.datasets[0] = {
    fill: true,
    data: Object.values(props.data),
    borderColor: theme.palette.chart.blue.main,
    backgroundColor: theme.palette.chart.blue.o50,
    pointBackgroundColor: theme.palette.chart.blue.main,
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          format: {
            style: 'percent',
          },
          //   maxTicksLimit: 8,
        },
      },
    },
    elements: {
      line: {
        // tension: 5,
      },
      point: {
        radius: 4,
        backgroundColor: theme.palette.chart.blue.main,
      },
    },
    plugins: {
      //   tooltip: {
      //     enabled: false,
      //   },
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
  };

  return <Line options={options} data={data} />;
};

export default AreaChart;
