import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Navigate, useOutletContext } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chip from '@mui/material/Chip';
import Head from '../components/Head';
import DashboardToolbar from '../components/toolbars/DashboardToolbar';
import RevenueWebKpis from '../components/kpis/RevenueWebKpis';
import RevenueOtherKpis from '../components/kpis/RevenueOtherKpis';
import RevenueByService from '../components/grids/RevenueByService';
import GamRevenue from '../components/kpis/GamRevenue';
import GamEcpm from '../components/kpis/GamEcpm';
import GamViewability from '../components/kpis/GamViewability';
import GamImpressions from '../components/kpis/GamImpressions';

const Revenue = (props) => {
  const { permissions } = useOutletContext();
  const canViewRevenue = permissions && permissions.revenue;

  const { drawerOpen, setDrawerOpen } = useOutletContext();
  const { apiRequestOptions } = useOutletContext();
  const { userDefaultFilter } = useOutletContext();
  const { domains, setDomains } = useOutletContext();

  const [startDate, setStartDate] = useState(DateTime.now().minus({ days: 7 }));
  const [dateRangeLabel, setDateRangeLabel] = useState('Last 7 Days');
  const [endDate, setEndDate] = useState(DateTime.now().minus({ days: 1 }));

  const [loadingRevenueWebKpis, setLoadingRevenueWebKpis] = useState(true);
  const [loadingRevenueOtherKpis, setLoadingRevenueOtherKpis] = useState(true);
  const [loadingRevenueByService, setLoadingRevenueByService] = useState(true);
  const [loadingGamRevenue, setLoadingGamRevenue] = useState(true);
  const [loadingGamEcpm, setLoadingGamEcpm] = useState(true);
  const [loadingGamViewability, setLoadingGamViewability] = useState(true);
  const [loadingGamImpressions, setLoadingGamImpressions] = useState(true);

  const theme = useTheme();
  const xlUp = useMediaQuery(theme.breakpoints.up('xl'));

  const ResponsiveDivider = ({ children }) => {
    return <Divider orientation={xlUp ? 'vertical' : 'horizontal'} flexItem sx={{ width: { xs: '100%', xl: 'auto' } }} />;
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDomainChange = (event, newValue) => {
    setDomains(newValue);
  };

  const handleDateRangeChange = (start, end, label) => {
    setStartDate(start);
    setEndDate(end);
    setDateRangeLabel(label);
  };

  useEffect(() => {
    if (userDefaultFilter && !domains) {
      setDomains(userDefaultFilter.configuration.domains);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDefaultFilter]);

  if (canViewRevenue === true) {
    return (
      <>
        <Head title="Revenue"></Head>
        <DashboardToolbar
          pageTitle={
            <>
              Revenue <Chip label="Beta" size="small" />
            </>
          }
          handleDrawerToggle={handleDrawerToggle}
          apiRequestOptions={apiRequestOptions}
          domains={domains}
          handleDomainChange={handleDomainChange}
          startDate={startDate}
          endDate={endDate}
          handleDateRangeChange={handleDateRangeChange}
          disabled={
            loadingRevenueOtherKpis ||
            loadingRevenueWebKpis ||
            loadingRevenueByService ||
            loadingGamRevenue ||
            loadingGamEcpm ||
            loadingGamViewability ||
            loadingGamImpressions
          }
        />
        <Box></Box>
        <Grid container spacing={3} sx={{ py: 3 }}>
          <Grid item xs={12} lg={4}>
            <Paper elevation={0} sx={{ py: 3, px: 0 }}>
              <Box sx={{ mb: 2, px: 3 }}>
                <Typography variant="h1" gutterBottom sx={{ mr: 1 }}>
                  Web Subscriptions
                </Typography>
              </Box>
              <RevenueWebKpis
                domains={domains}
                startDate={startDate}
                endDate={endDate}
                apiRequestOptions={apiRequestOptions}
                setLoadingRevenueWebKpis={setLoadingRevenueWebKpis}
              />
              <Divider sx={{ my: 3 }}></Divider>
              <Box sx={{ px: 3 }}>
                <RevenueByService
                  domains={domains}
                  startDate={startDate}
                  endDate={endDate}
                  apiRequestOptions={apiRequestOptions}
                  dateRangeLabel={dateRangeLabel}
                  setLoadingRevenueByService={setLoadingRevenueByService}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Paper elevation={0} sx={{ py: 3, px: 0 }}>
              <Box sx={{ mb: 2, px: 3 }}>
                <Typography variant="h1" gutterBottom sx={{ mr: 1 }}>
                  Advertising
                </Typography>
              </Box>
              <Grid container sx={{ px: 3, gap: { xs: 2, xl: 5 }, flexWrap: { xl: 'nowrap' } }}>
                <Grid item xs={12} sm="auto">
                  <GamRevenue
                    domains={domains}
                    startDate={startDate}
                    endDate={endDate}
                    apiRequestOptions={apiRequestOptions}
                    dateRangeLabel={dateRangeLabel}
                    setLoadingGamRevenue={setLoadingGamRevenue}
                  />
                </Grid>

                <ResponsiveDivider />

                <Grid item xs={12} sm>
                  <GamEcpm
                    domains={domains}
                    startDate={startDate}
                    endDate={endDate}
                    apiRequestOptions={apiRequestOptions}
                    dateRangeLabel={dateRangeLabel}
                    setLoadingGamEcpm={setLoadingGamEcpm}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 3 }}></Divider>
              <Grid container sx={{ px: 3, gap: { xs: 2, xl: 5 }, flexWrap: { xl: 'nowrap' } }}>
                <Grid item xs={12} sm="auto">
                  <GamViewability
                    domains={domains}
                    startDate={startDate}
                    endDate={endDate}
                    apiRequestOptions={apiRequestOptions}
                    dateRangeLabel={dateRangeLabel}
                    setLoadingGamViewability={setLoadingGamViewability}
                  />
                </Grid>

                <ResponsiveDivider />

                <Grid item xs={12} xl>
                  <GamImpressions
                    domains={domains}
                    startDate={startDate}
                    endDate={endDate}
                    apiRequestOptions={apiRequestOptions}
                    dateRangeLabel={dateRangeLabel}
                    setLoadingGamImpressions={setLoadingGamImpressions}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Paper elevation={0}>
              <Box sx={{ p: 3 }}>
                <RevenueOtherKpis
                  domains={domains}
                  startDate={startDate}
                  endDate={endDate}
                  apiRequestOptions={apiRequestOptions}
                  setLoadingRevenueOtherKpis={setLoadingRevenueOtherKpis}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  } else if (canViewRevenue === false) {
    return <Navigate to="/signin" replace />;
  }
};

export default Revenue;
