import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { DateTime } from 'luxon';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AreaChart from '../charts/AreaChart';
import SectionTitle from '../SectionTitle';

/**
 *
 * get data logic goes here
 * let's fake for now
 *
 **/
const fakeData = {
  metric: [
    { utc_month: '2021-08', rate: 0.4412 },
    { utc_month: '2021-09', rate: 0.5377 },
    { utc_month: '2021-10', rate: 0.5739 },
    { utc_month: '2021-11', rate: 0.5889 },
    { utc_month: '2021-12', rate: 0.6416 },
    { utc_month: '2022-01', rate: 0.4319 },
    { utc_month: '2022-02', rate: 0.5378 },
    { utc_month: '2022-03', rate: 0.4595 },
    { utc_month: '2022-04', rate: 0.6194 },
    { utc_month: '2022-05', rate: 0.663 },
    { utc_month: '2022-06', rate: 0.5004 },
    { utc_month: '2022-07', rate: 0.6137 },
  ],
  paid: [
    { utc_month: '2021-08', rate: 0.1859 },
    { utc_month: '2021-09', rate: 0.514 },
    { utc_month: '2021-10', rate: 0.7353 },
    { utc_month: '2021-11', rate: 0.7611 },
    { utc_month: '2021-12', rate: 0.9516 },
    { utc_month: '2022-01', rate: 0.7859 },
    { utc_month: '2022-02', rate: 0.3946 },
    { utc_month: '2022-03', rate: 0.3336 },
    { utc_month: '2022-04', rate: 0.8105 },
    { utc_month: '2022-05', rate: 0.1925 },
    { utc_month: '2022-06', rate: 0.1424 },
    { utc_month: '2022-07', rate: 0.28 },
  ],
};

const IndustryConversionsModal = (props) => {
  const [fetchingData, setFetchingData] = useState(false);
  const [rows, setRowData] = useState(null);

  const getData = (props) => {
    if (!fetchingData) {
      setRowData(null);
      setFetchingData(true);

      // fake api call
      setTimeout(() => {
        let data = fakeData[props.type];

        let nData = {};
        data.forEach((d) => (nData[DateTime.fromISO(d.utc_month).monthShort] = d.rate));

        setRowData(nData);
        setFetchingData(false);
      }, 600);
    }
  };

  useEffect(() => {
    if (props.open) getData(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        elevation: 1,
      }}>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          columnGap: 1,
          justifyContent: 'space-between',
          fontWeight: 'bold',
        }}>
        <Box>
          {props.title && <SectionTitle title={props.title} size="lg" />}
          {props.subtitle && (
            <Typography variant="subtitle1" sx={{ mb: { xs: 2, sm: 2 } }}>
              {props.subtitle}
            </Typography>
          )}
        </Box>

        <IconButton aria-label="close" onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{rows ? <AreaChart data={rows} /> : <Skeleton height={400} />}</DialogContent>
    </Dialog>
  );
};

export default IndustryConversionsModal;
