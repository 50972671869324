import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import Kpi from './Kpi';
import { trackWidgetView } from '../../helpers/Analytics';

const SubscriptionRevenueKpis = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [fetchingData, setfetchingData] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [totalSubscribers, setTotalSubscribers] = useState(null);
  const [newSubscribers, setNewSubscribers] = useState(null);
  const [totalRevenue, setTotalRevenue] = useState(null);
  const [newRevenue, setNewRevenue] = useState(null);
  const [totalSubscribersDiff, setTotalSubscribersDiff] = useState(null);
  const [newSubscribersDiff, setNewSubscribersDiff] = useState(null);
  const [totalRevenueDiff, setTotalRevenueDiff] = useState(null);
  const [newRevenueDiff, setNewRevenueDiff] = useState(null);
  const [daysDiff, setDaysDiff] = useState(null);

  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const priorPeriod = daysDiff < 365 ? 'Prior month' : 'Prior year';

  const ResponsiveDivider = ({ children }) => {
    return <Divider orientation={lgUp ? 'vertical' : 'horizontal'} flexItem sx={{ width: { xs: '100%', lg: 'auto' } }} />;
  };

  const fetchData = () => {
    if (props.apiRequestOptions) {
      setLoadingData(true);
      setfetchingData(true);

      var oParams = new URLSearchParams({
        hostname: props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/blox_subscription/revenue_summary', oAxiosOptions).then(
        (response) => {
          if (
            response.data &&
            response.data.hasOwnProperty('total_subscribers') &&
            response.data.hasOwnProperty('new_subscribers') &&
            response.data.hasOwnProperty('total_revenue') &&
            response.data.hasOwnProperty('new_revenue')
          ) {
            let data = response.data;
            setTotalSubscribers(data.total_subscribers || 0);
            setNewSubscribers(data.new_subscribers || 0);
            setTotalRevenue(data.total_revenue || 0);
            setNewRevenue(data.new_revenue || 0);
            setTotalSubscribersDiff(data.total_subscribers_diff_pct);
            setNewSubscribersDiff(data.new_subscribers_diff_pct);
            setTotalRevenueDiff(data.total_revenue_diff_pct);
            setNewRevenueDiff(data.new_revenue_diff_pct);
            setDaysDiff(data.days_diff);
          } else {
            setDisplayError({ message: 'Unexpected data format.' });
          }

          setfetchingData(false);
          setLoadingData(false);
          trackWidgetView({ name: 'Subscription Revenue KPIs' });
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingData && props.domains) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains, props.startDate, props.endDate]);

  useEffect(() => {
    props.setLoadingSubscriptionRevenueKpis(fetchingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingData]);

  return (
    <Grid container sx={{ mt: 2, gap: 2 }}>
      {/* total subscribers kpi */}
      <Grid item xs={12} lg>
        {!loadingData && Number.isInteger(totalSubscribers) ? (
          <Kpi
            value={totalSubscribers}
            label="Total Subscribers"
            diff={totalSubscribersDiff}
            period={priorPeriod}
            align={lgUp ? 'center' : ''}
          />
        ) : (
          <Skeleton variant="rectangular" height={90} width={'80%'} sx={{ mx: 'auto' }} />
        )}
      </Grid>

      <ResponsiveDivider />

      {/* new subscribers kpi */}
      <Grid item xs={12} lg>
        {!loadingData && Number.isInteger(newSubscribers) ? (
          <Kpi
            value={newSubscribers}
            label="New Subscribers"
            diff={newSubscribersDiff}
            period={priorPeriod}
            align={lgUp ? 'center' : ''}
          />
        ) : (
          <Skeleton variant="rectangular" height={90} width={'80%'} sx={{ mx: 'auto' }} />
        )}
      </Grid>

      <ResponsiveDivider />

      {/* total revenue kpi */}
      <Grid item xs={12} lg>
        {!loadingData && Number.isInteger(totalRevenue) ? (
          <Kpi
            value={totalRevenue}
            type="money"
            label="Total Transaction Revenue"
            tip="Transaction revenue collected in this time period."
            diff={totalRevenueDiff}
            period={priorPeriod}
            align={lgUp ? 'center' : ''}
          />
        ) : (
          <Skeleton variant="rectangular" height={90} width={'80%'} sx={{ mx: 'auto' }} />
        )}
      </Grid>

      <ResponsiveDivider />

      {/* new revenue kpi */}
      <Grid item xs={12} lg>
        {!loadingData && Number.isInteger(newRevenue) ? (
          <Kpi
            value={newRevenue}
            type="money"
            label="New Transaction Revenue"
            tip="Transaction revenue collected in this time period for new subscriptions."
            diff={newRevenueDiff}
            period={priorPeriod}
            align={lgUp ? 'center' : ''}
          />
        ) : (
          <Skeleton variant="rectangular" height={90} width={'80%'} sx={{ mx: 'auto' }} />
        )}
      </Grid>
    </Grid>
  );
};

export default SubscriptionRevenueKpis;
