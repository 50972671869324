import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import BarChart from './BarChart';
import { DateTime } from 'luxon';
import { useOutletContext } from 'react-router-dom';
import axios from 'axios';
import SectionTitle from '../SectionTitle';
import { trackWidgetView } from '../../helpers/Analytics';
import ExportButton from '../input/ExportButton';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ConversionsChart = (props) => {
  const { setDisplayError } = useOutletContext();
  const { filtersReady } = useOutletContext();
  const [chartData, setChartData] = useState(null);
  const [fetchingChartData, setFetchingChartData] = useState(false);
  const [loadingChart, setLoadingChart] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const colors = theme.palette;

  const handleMenuToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const fetchChartData = () => {
    if (!fetchingChartData && props.apiRequestOptions) {
      setLoadingChart(true);
      setFetchingChartData(true);

      let now = DateTime.now();
      let lastMonth = now.minus({ months: 1 });
      let start = lastMonth.minus({ months: 11 }).startOf('month');
      let end = lastMonth.endOf('month');

      setStartDate(start);
      setEndDate(end);

      var oParams = new URLSearchParams({
        hostname: props.domains,
        startDate: start,
        endDate: end,
      });

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        params: oParams,
      };

      axios.get('/api/v1/blox_subscription/conversions', oAxiosOptions).then(
        (response) => {
          if (response.data && response.data.constructor === Array) {
            let barChartData = {};
            for (let i = 0; i < response.data.length; i++) {
              const e = response.data[i];
              let d = DateTime.fromISO(e.date).monthShort;
              barChartData[d] = e.conversions;
            }
            setChartData(barChartData);
          } else {
            setDisplayError({ message: 'Unexpected data format.' });
          }

          setFetchingChartData(false);
          setLoadingChart(false);
          trackWidgetView({ name: 'Conversions by Month' });
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  useEffect(() => {
    if (filtersReady && !fetchingChartData && props.domains) {
      fetchChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.domains]);

  useEffect(() => {
    props.setLoadingConversionsChart(fetchingChartData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchingChartData]);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', columnGap: 2 }}>
        <SectionTitle title="Conversions by Month" size="sm" />
        {startDate && endDate && (
          <Box>
            <IconButton
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              disabled={fetchingChartData}
              onClick={handleMenuToggle}>
              <MoreVertOutlinedIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClick={() => setAnchorEl(null)}
              keepMounted
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{
                '& .MuiMenuItem-root': {
                  gap: 1,
                  paddingLeft: '12px',
                  fontSize: '.9em',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '1.25rem',
                  color: colors.primary.light, 
                },
              }}>
              <MenuItem sx={{ padding: '0 !important' }}>
                <ExportButton
                  variant="link"
                  sx={{
                    color: 'white',
                    gap: 1,
                    paddingLeft: '12px',
                    fontSize: '.9em',
                    fontWeight: 400,
                  }}
                  {...props}
                  startDate={startDate}
                  endDate={endDate}
                  modal={true}
                  startImmediateExport={true}
                  export={{
                    api: '/api/v1/blox_subscription/conversions',
                    name: 'conversions-by-month',
                  }}
                />
              </MenuItem>
            </Menu>
          </Box>
        )}
      </Box>

      {!loadingChart && chartData ? (
        <Box sx={{ position: 'relative', width: '100%', height: 300, mt: 1 }}>
          <BarChart data={chartData} height={300} type="vertical"></BarChart>
        </Box>
      ) : (
        <Skeleton variant="rectangular" height={300} />
      )}
    </Box>
  );
};

export default ConversionsChart;
