import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';

const Placeholder = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'height',
})(({ theme, height }) => ({
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  backgroundColor: alpha(theme.palette.background.default, theme.palette.action.disabledOpacity),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: height || 200,
}));

export default Placeholder;
