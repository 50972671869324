import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import { Box, Card, Container, Typography, Button, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SpeedIcon from '@mui/icons-material/Speed';
import { Navigate, useSearchParams } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAuth0 } from '@auth0/auth0-react';
import { theme } from '../theme';
import { PRODUCT_NAME, REQUIRED_ORG } from '../constants';
import Head from '../components/Head';

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 250,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

const SignIn = (props) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();
  const invitation = searchParams.get('invitation');
  const organization = searchParams.get('organization');
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  if (isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  if (!isLoading && invitation && organization) {
    loginWithRedirect({ invitation: invitation, organization: organization });
  }

  return (
    <ThemeProvider theme={theme}>
      <Head title="Sign In"></Head>
      <CssBaseline />

      <RootStyle>
        <HeaderStyle>
          {!mdUp && (
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
              <Typography variant="h3" sx={{ fontWeight: 700 }}>
                {PRODUCT_NAME}
              </Typography>
            </Box>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            {/*
            <Box sx={{ textAlign: 'center', maxWidth: '200px', marginX: 'auto' }}>
              <Typography variant="h3" sx={{ mb: 0.5 }}>
                {PRODUCT_NAME}
              </Typography>
              <Typography variant="subtitle2">Upgrade</Typography>
            </Box>
            */}
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{ mx: 2, my: 4 }}>
              <SpeedIcon />
              <Typography variant="h3">{PRODUCT_NAME}</Typography>
            </Stack>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          {isLoading && (
            <ContentStyle>
              <Box sx={{ textAlign: 'center' }}>
                <CircularProgress />
              </Box>
            </ContentStyle>
          )}
          {!isLoading && (
            <ContentStyle>
              <Typography variant="h1" gutterBottom>
                Sign in
              </Typography>
              <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                A {REQUIRED_ORG} organization account is required to continue.
              </Typography>
              <Button size="large" variant="contained" onClick={() => loginWithRedirect()}>
                Sign in with SSO
              </Button>
            </ContentStyle>
          )}
        </Container>
      </RootStyle>
    </ThemeProvider>
  );
};

export default SignIn;
