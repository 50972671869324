import { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export default function PositionedSnackbar(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (props.displayError) {
      setErrorMessage(props.displayError.message);
      setIsOpen(true);
    }
  }, [props.displayError]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpen}
      onClose={handleClose}
      message={errorMessage}>
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }} elevation={3}>
        <AlertTitle>Data Unavailable</AlertTitle>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
}
