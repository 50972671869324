import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function DataTypeSelect(props) {
  const [datatype, setDataType] = useState(props.type);

  useEffect(() => {
    setDataType(props.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type]);

  return (
    <FormControl sx={{ m: 0, minWidth: 120 }} size="small">
      <Select
        value={datatype}
        autoWidth
        onChange={props.onChange}
        displayEmpty
        disabled={props.disabled}
        sx={{ fontSize: '14px' }}>
        {props.types.map((item, i) => (
          <MenuItem value={item.type} key={i} sx={{ fontSize: '14px' }}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
