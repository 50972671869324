import * as React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import { useTheme } from '@mui/material/styles';
import { DateTime } from 'luxon';
import Placeholder from './Placeholder';

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend, Filler);

const PageviewsChart = (props) => {
  const theme = useTheme();
  const userTimezone = DateTime.now().toFormat('ZZZZZ');
  const { chartType = 'bar', showLegend = false } = props;
  ChartJS.defaults.font.family = theme.typography.fontFamily;
  ChartJS.defaults.color = theme.palette.text.primary;
  ChartJS.defaults.borderColor = theme.palette.divider;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
        labels: {
          padding: 15,
          usePointStyle: true,
        },
      },
      datalabels: {
        display: false,
      },
    },
    parsing: {
      xAxisKey: 'hour',
      yAxisKey: 'pageviews',
    },
    scales: {
      x: {
        title: {
          display: true,
          text: userTimezone,
          padding: {
            top: 15,
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 6.3,
        },
      },
      y: {
        grid: {
          drawBorder: false,
          color: theme.palette.divider,
        },
      },
    },
  };

  if (showLegend) {
    options.plugins.legend.display = true;
  }

  if (
    props.chartData &&
    props.chartData.datasets &&
    props.chartData.datasets[0].data &&
    Array.isArray(props.chartData.datasets[0].data) &&
    props.chartData.datasets[0].data.length > 0
  ) {
    return chartType === 'line' ? (
      <Line options={options} data={props.chartData} />
    ) : (
      <Bar options={options} data={props.chartData} />
    );
  } else {
    return (
      <Placeholder elevation={0} height={250}>
        No Data
      </Placeholder>
    );
  }
};

export default PageviewsChart;
