import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Auth0ProviderWithHistory } from './auth0-provider-with-history';
import { RequireAuth } from './components/auth/RequireAuth';
import Index from './pages/Index';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import Editorial from './pages/Editorial';
import Subscriptions from './pages/Subscriptions';
import Revenue from './pages/Revenue';
import SignIn from './pages/SignIn';
import { initAnalytics } from './helpers/Analytics';

const AppRoutes = () => {

  initAnalytics();

  return (
    <HelmetProvider>
      <Auth0ProviderWithHistory>
        <Routes>
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Index />}>
              <Route index element={<Navigate to="/dashboard" replace />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="settings" element={<Settings />} />
              <Route path="editorial" element={<Editorial />} />
              <Route path="subscriptions" element={<Subscriptions />} />
              <Route path="revenue" element={<Revenue />} />
            </Route>
          </Route>
          <Route element={<Index />}>
            <Route path="/dashboard/:dashboardId" element={<Dashboard />} />
          </Route>
          <Route path="/signin" element={<SignIn />} />
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Auth0ProviderWithHistory>
    </HelmetProvider>
  );
};

export default AppRoutes;
