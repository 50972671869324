import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const ExportModal = (props) => {
  const { setDisplayError } = useOutletContext();
  const [fetchingData, setFetchingData] = useState(false);
  const [rowCount, setRowCount] = useState(25);
  const [showLoadingBtn, setShowLoadingBtn] = useState(false);
  const exportNumberOfRowsOptions = [25, 50, 100, 5000];
  const [exportName, setExportName] = useState(props.export.name);
  const [downloadFile, setDownloadFile] = useState(null);
  const [fallback, setFallback] = useState(null);
  const [startImmediateExport, setStartImmediateExport] = useState(false);

  const handleFileDownload = () => {
    const link = document.createElement('a');
    link.href = downloadFile;
    link.setAttribute('download', exportName + '.csv');
    link.click();
  };

  const handleExportClick = () => {
    if (props.apiRequestOptions) {
      setFetchingData(true);
      setShowLoadingBtn(true);

      // defaults
      var oParams = new URLSearchParams({
        page: 1,
        pageSize: 10, 
        hostname: props.hostname ? props.hostname : props.domains,
        startDate: props.startDate.toISODate(),
        endDate: props.endDate.toISODate(),
        export: 'csv',
        exportRows: rowCount,
        exportFileName: exportName,
      });

      // api specific
      
      if(props.export && props.export.params){
        Object.entries(props.export.params).forEach((item) => {
          if (item[0] !== 'localOnly') oParams.append(item[0], item[1]);
        });
      }
      if (props.export.params && props.export.params.localOnly) oParams.append('localOnly', true);

      var oAxiosOptions = {
        headers: props.apiRequestOptions.headers,
        responseType: 'blob',
        params: oParams,
      };

      axios.get(props.export.api, oAxiosOptions).then(
        (response) => {
          if(response.data.type === 'application/csv'){
            let url = window.URL.createObjectURL(new Blob([response.data]));
            setDownloadFile(url);
          } else setFallback("Export unavailable at this time. Please try again later.");
          
          setFetchingData(false);
        },
        (error) => {
          setDisplayError(error);
        }
      );
    }
  };

  const handleRowCountChange = (event) => {
    setShowLoadingBtn(false);
    setDownloadFile(null);
    setRowCount(event.target.value);
  };

  const handleCloseExportModal = () => {
    props.handleCloseExportModal();
  };

  // determine if we should start immediate download
  useEffect(() => {
    // if startImmediateExport is flagged, set rowCount to 5000
    if (props.startImmediateExport) setRowCount(5000);
    // otherwise if totalRows is less than 100, set to 100
    else if (props.export.totalRows && props.export.totalRows <= 100) setRowCount(100);
    // otherwise just stick with default 25.

    // if either of these are true, start downloading immediately
    if (props.startImmediateExport || (props.export.totalRows && props.export.totalRows <= 100)) {
      setStartImmediateExport(true);
    } else {
      setStartImmediateExport(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startImmediateExport, props.export.totalRows]);

  // trigger immediate download if true
  useEffect(() => {
    if (startImmediateExport) {
      setShowLoadingBtn(true);
      if(props.exportModalOpen) handleExportClick();
    } else {
      setShowLoadingBtn(false);
      setRowCount(25);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.exportModalOpen, startImmediateExport]);

  useEffect(() => {
    let exportFileName = `${props.export.name}--${props.startDate.toISODate().replace(/-/g, '')}-${props.endDate
      .toISODate()
      .replace(/-/g, '')}`;
    if (props.export.params && props.export.params.localOnly) exportFileName += '__localonly';

    setExportName(exportFileName);
  }, [props.export.name, props.export.params, props.startDate, props.endDate]);

  // clear 'fallback' if props change
  useEffect(() => {
    setFallback(null);
  }, [props.hostname, props.startDate, props.endDate, props.export.params]);

  return (
    <Dialog
      open={props.exportModalOpen}
      onClose={handleCloseExportModal}
      fullWidth={true}
      maxWidth="xs"
      PaperProps={{
        elevation: 1,
      }}>
      <Box sx={{ p: 3, pb: 4 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
          <Typography variant="h1" gutterBottom sx={{ mb: 0 }}>
            Export as CSV
          </Typography>
          <IconButton aria-label="close" onClick={handleCloseExportModal}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography variant="subtitle2" gutterBottom>
          Exported data will match currently applied filters.
        </Typography>

        {!fallback ? (
          <Box sx={{ mt: 4 }}>
            {!startImmediateExport && (
              <Box>
                <Typography gutterBottom>Specify how much data to export:</Typography>
                <FormControl fullWidth size="small">
                  <Select id="demo-simple-select" value={rowCount} onChange={handleRowCountChange}>
                    {exportNumberOfRowsOptions.map((v, i) => (
                      <MenuItem key={i} value={v}>
                        Top {v} Rows
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            <Box sx={{ mt: 1.5 }}>
              {showLoadingBtn ? (
                fetchingData ? (
                  <Button size="medium" variant="primary" disabled>
                    <CircularProgress sx={{ width: '1.25em !important', height: '1.25em !important' }} />
                    Downloading...
                  </Button>
                ) : (
                  <Button size="medium" variant="primary" onClick={handleFileDownload}>
                    Download
                  </Button>
                ) 
              ) : (
                <Button size="medium" variant="white" onClick={handleExportClick}>
                  Begin Export
                </Button>
              )}
            </Box>
          </Box>
        ) : (
          <Alert severity='error' sx={{mt: 2}}>{fallback}</Alert>
        )}
      </Box>
    </Dialog>
  );
};

export default ExportModal;
