import base from './base';

const typography = {
  fontFamily: ['Inter', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
    ','
  ),
  h1: {
    fontSize: '1.6rem',
    fontWeight: 700,
    [base.breakpoints.up('sm')]: {
      fontSize: '2rem',
    },
  },
  h2: {
    fontSize: '1.2rem',
    fontWeight: 700,
    [base.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },
  },
  h3: {
    fontSize: '1rem',
    fontWeight: 700,
  },
  subtitle1: {
    fontSize: '0.9rem',
    fontWeight: 400,
    color: base.palette.grey[400],
    [base.breakpoints.up('sm')]: {
      fontSize: '1rem',
    },
  },
  subtitle2: {
    fontSize: '0.8rem',
    fontWeight: 400,
    color: base.palette.grey[400],
    [base.breakpoints.up('sm')]: {
      fontSize: '0.9rem',
    },
  },
  pageTitle: {
    fontSize: '1.6rem',
    fontWeight: 700,
    [base.breakpoints.up('sm')]: {
      fontSize: '2rem',
    },
  },
  kpi: {
    fontSize: '3rem',
    lineHeight: '4rem',
    fontWeight: 700,
    color: base.palette.primary.main,
  },
  kpiLabel: {
    fontSize: '1rem',
    fontWeight: 700,
    [base.breakpoints.up('sm')]: {
      fontSize: '1.1rem',
    },
  },
  button: {
    textTransform: 'none',
  },
  data: {
    color: base.palette.text.secondary,
  },
  header_lg: {
    fontSize: '1.35rem',
    display: 'flex',
    alignItems: 'baseline',
    fontWeight: 700,
    [base.breakpoints.up('sm')]: {
      fontSize: '1.75rem',
    },
  },
  header_sm: {
    fontSize: '1.1rem',
    display: 'flex',
    alignItems: 'baseline',
    fontWeight: 700,
    [base.breakpoints.up('sm')]: {
      fontSize: '1.25rem',
    },
  },
};

export default typography;
