import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Auth0ProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const cacheLocation = process.env.REACT_APP_AUTH0_CACHE_LOCATION;

  const redirectUri = window.location.origin + '/ui/signin';

  const onRedirectCallback = (appState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={redirectUri}
      scope="datainsights:view:dashboard datainsights:manage:filters datainsights:view-dashboard:trending datainsights:view-dashboard:editorial datainsights:view-dashboard:subscription datainsights:view-dashboard:revenue"
      onRedirectCallback={onRedirectCallback}
      cacheLocation={cacheLocation}
      useRefreshTokens={true}>
      {children}
    </Auth0Provider>
  );
};
